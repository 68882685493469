import * as React from 'react'
import {FC} from 'react'
import {
  Show,
  TabbedShowLayout,
  DateField,
  Tab,
  EditProps,
  TextField,
} from 'react-admin'
import {PersonalPageBlockList} from 'src/resources/personal-page/PersonalPageBlockList'
import ProfileLinkField from 'src/components/fields/ProfileLinkField'

interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Персональная страница #{record.id}</span>

const UserShow: FC<EditProps> = props => {
  return (<Show {...props} title={<Title/>}>
    <TabbedShowLayout>
      <Tab label="Информация">
        <TextField source={'id'} label={'ID'}/>
        <ProfileLinkField label={'Профиль'} source={'profile'}/>
        <DateField source={'createdAt'} label={'Создано'} showTime/>
        <DateField source={'updatedAt'} label={'Обновлено'} showTime/>
        <PersonalPageBlockList />
      </Tab>

    </TabbedShowLayout>
  </Show>)
}

export default UserShow