import ListIcon from '@mui/icons-material/List'
import CharacterItemList from 'src/resources/character-item/CharacterItemList'
import CharacterItemEdit from 'src/resources/character-item/CharacterItemEdit'
import CharacterItemCreate from 'src/resources/character-item/CharacterItemCreate'

export default {
  create: CharacterItemCreate,
  edit: CharacterItemEdit,
  list: CharacterItemList,
  icon: ListIcon,
}