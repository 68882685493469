import * as React from 'react'
import {
  BooleanInput,
  SimpleForm,
  SimpleFormIterator,
  required,
  SelectInput,
  TextInput, ArrayInput,
} from 'react-admin'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import {LangList} from 'src/types'


export const PageForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <BooleanInput source={'published'} label={'Опубликовано'}/>
      <TextInput source={'key'} label={'Ключ'} validate={required()}  variant={'outlined'} />
      <ArrayInput source="translations" label={'Контент'}>
        <SimpleFormIterator fullWidth>
          <SelectInput source="languageCode" label={'Язык'}  variant={'outlined'} choices={LangList} validate={required()} />
          <TextInput source={'title'} label={'Название'} validate={required()}  fullWidth={true} variant={'outlined'}/>
          <RichTextInput source="body" label={'Содержимое'} fullWidth={true} variant={'outlined'} validate={required()} />
        </SimpleFormIterator>
      </ArrayInput>



    </SimpleForm>
  )
}
