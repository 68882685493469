import * as React from 'react'
import {
  TabbedForm,
  FormTab,
} from 'react-admin'
import {useFormContext} from 'react-hook-form'

const InfoTab = (props: any) => {
  const form = useFormContext()
  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>
    </FormTab>)
}
const CharacterForm = (props: any) => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
    </TabbedForm>
  )
}
export default CharacterForm
