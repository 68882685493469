import ListIcon from '@mui/icons-material/List'
import StoryBlockList from 'src/resources/story-block/StoryBlockList'
import StoryBlockEdit from 'src/resources/story-block/StoryBlockEdit'
import StoryBlockCreate from 'src/resources/story-block/StoryBlockCreate'

export default {
  create: StoryBlockCreate,
  edit: StoryBlockEdit,
  list: StoryBlockList,
  icon: ListIcon,
}