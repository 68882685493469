import * as React from 'react'
import {FC} from 'react'
import {
  EditProps,
} from 'react-admin'
import FeedbackForm from 'src/resources/feedback/FeedbackForm'
import {CustomEdit} from 'src/components/CustomEdit'

interface TitleProps {
  record?: any;
}
const Title: FC<TitleProps> = ({record}) =>
  record ? <span>Отзыв {record.name}</span> : null

const FeedbackEdit: FC<EditProps> = props => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic" title={<Title/>}>
    <FeedbackForm/>
    </CustomEdit>
  )
}
export default FeedbackEdit