import * as React from 'react'
import { FieldProps, RaRecord} from 'react-admin'
import LinkField from 'src/components/fields/LinkField'
interface Props<RecordType extends RaRecord = any> extends FieldProps {
}

const StoryBlockLinkField = (props: Props) => {
   return  <LinkField link={(record) => `/story/${record[props.source ?? 'story']?.id}`} source={props.source ?? 'story.name'} value={(record) => record[props.source ?? 'story']?.name}  label={props.label}/>
}

export default StoryBlockLinkField
