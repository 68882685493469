import * as React from 'react'
import {RecordContextProvider, useDataProvider, useRefresh} from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import {FieldValues} from 'react-hook-form'
import {useRecordContext} from 'ra-core'
import {useEffect, useState} from 'react'
import {CourseBlockStepEditForm} from 'src/resources/course/step/CourseBlockStepEditForm'
interface Props{
    refresh?: boolean
    isShown?: boolean
    id?: number
    mainRecord: Record<any, any>
    onClose?: () => void
}
export const CourseBlockStepEditModal = (props: Props) => {
    const baseAreaRecord = useRecordContext()
    const [record, setRecord] = useState<Record<any, any> | null>(null)
    const [isLoading, setIsLoading] = useState(true)
    const refresh = useRefresh()

    const dataProvider = useDataProvider()
    useEffect(() => {
        dataProvider.getOne('course-block-step', { id: baseAreaRecord.id })
            .then(({ data }) => {
                setRecord(data)
                setIsLoading(false)
            })
    }, [])
    const handleSubmit = async (data: FieldValues) => {
        const res = await dataProvider.update('course-block-step', {id: baseAreaRecord.id, data, previousData: record})
        refresh()
        if(props.onClose){
            props.onClose()
        }
        return true
    }
    console.log('AreaLoading',isLoading, record)

    return  <RecordContextProvider  value={record ?? {}}>
        <ModalForm
        isShown
        isLoading={isLoading}
        defaultValues={{preview: 'medium'}}
        fullWidth={true}
        maxWidth={'lg'}
        title={record ? 'Изменить шаг блока' : 'Новый Блок'}
        onClose={props.onClose}
        save={handleSubmit}
        record={record}
    >
            <CourseBlockStepEditForm />

        </ModalForm>
    </RecordContextProvider>
}
CourseBlockStepEditModal.defaults = {
    refresh: true
}