import React from 'react'
import { useDataProvider, useRefresh, ReferenceInput, SelectInput
} from 'react-admin'

import ModalForm from 'src/components/ModalForm'
import {FieldValues} from 'react-hook-form'
import {getTranslation} from 'src/utils/translation'
interface Props{
}
export const BackgroundCategoryChangeParentForm = (props: any) => {
  const { record} = props
  const refresh = useRefresh()
  const dataProvider = useDataProvider()

  const handleSubmit = async (data: FieldValues) => {
      await dataProvider.update('background-category', {id: record.id, data: {parentId: data.parentId}, previousData: record})

    refresh()
    return true
  }

  return <ModalForm
    {...props}
    title={`Перемещение категории «${getTranslation(record)?.name}»`}
    handleClose={props.onClose}
    save={handleSubmit}
    fullWidth={true}
    record={record}
  >
    <ReferenceInput
      source="parentId"
      reference="background-category"
      sort={{ field: 'translations.name', order: 'ASC' }}
      filter={{'translations.languageCode': 'ru'}}
    >
      <SelectInput source="name"       variant={'outlined'}
                   label={'Переместить в категорию:'}
                   fullWidth optionText={(i: any) => getTranslation(i)?.name}/>
    </ReferenceInput>
  </ModalForm>
}
