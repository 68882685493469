import * as materialColors from '@mui/material/colors'
import {defaultTheme} from 'react-admin'

const colors = {
    primary: '#3B8AFF',
    textPrimary: '#FFFFFF',
    textSecondary: '#737389',
    textDark: '#16161B',
    bg: '#16161B',
    bgSecondary: '#22222A',
    secondary: materialColors.blue[700],
    borderBg: '#2E2E38'
}


export const darkTheme = {

    ...defaultTheme,

    palette: {
        primary: {
            main: colors.primary,
            dark: colors.primary
        },
        secondary: {
            main: colors.primary,
            dark: colors.primary
        },
        text: {
            primaryText: colors.textPrimary,
            secondary: colors.textSecondary,
        },
        type: 'dark' // Switching the dark mode on is a single property value change.
    },
    overrides: {
        sidebar: {
            width: 340,
            closedWidth: 55,
        },
        RaSidebar: {
            fixed: {
                zIndex: 1200,
            },
        },
    },
    components: {
        ...defaultTheme.components,
        RaLayout: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.bg,
                    color: colors.textPrimary,
                    '& .RaLayout-content': {
                        backgroundColor: colors.bg,
                    },
                }
            },
        },

        RaAppBar: {
            styleOverrides: {
                root: {
                    color: colors.primary,
                    backgroundColor: colors.bg,
                    boxShadow: 'none',
                    borderBottom: `1px solid ${colors.borderBg}`
                }
            }
        },
        RaSidebar: {
            styleOverrides: {
                root: {
                    '& .MuiPaper-root': {
                      width:'255px'
                    },
                    '& .RaSidebar-fixed': {
                        zIndex: 1200,
                        backgroundColor: colors.bg,
                        borderRight: `1px solid ${colors.borderBg}`,
                        borderTop: `1px solid ${colors.borderBg}`,
                        width: '255px'
                    },
                }
            }
        },
        RaLabeled: {
            styleOverrides: {
                root: {
                    '& .RaLabeled-label': {
                        color: colors.textSecondary
                    }
                }
            }
        },
        RaMenuItem: {
            styleOverrides: {
                root: {
                    color: colors.textPrimary,
                    '.RaMenuItem-active': {
                        color: colors.primary,
                        '& span': {
                            color: colors.primary,
                        },

                    },

                },

            },
        },
        MuiListItemIcon: {
            styleOverrides: {

                root: {
                    color: colors.textPrimary,
                },
            },
        },
        MuiPaper: {
            styleOverrides: {

                root: {
                    color: colors.textPrimary,
                    backgroundColor: colors.bgSecondary
                },
            },
        },
        RaSimpleFormIterator: {
            defaultProps: {
                fullWidth: true,
            },
            styleOverrides: {
                root: {
                    '& .RaSimpleFormIterator-line': {
                        borderBottom: '1px solid #2E2E37'
                    }
                },
                action: {
                    '& button:disabled': {
                        color: 'red'
                    }
                }
            }
        },

        RaRichTextInput: {
            styleOverrides: {
                root: {
                    '& .RaRichTextInputToolbar-root': {
                        '& button': {
                            color: colors.textPrimary,
                            backgroundColor: '#2D2D37',
                            borderColor: '#2D2D37'
                        }
                    },
                    '.RaRichTextInput-editorContent': {
                        '& .ProseMirror': {
                            backgroundColor: 'transparent',
                            borderColor: '#737389'
                        },
                        '& .ProseMirror:focus': {
                            backgroundColor: 'transparent',
                            outline: '0'
                        },
                    }
                },

            },
        },
        RaToolbar: {
            styleOverrides: {
                root: {
                    backgroundColor: '#2B2B34 !important'
                },
            },
        },
        RaBreadcrumb: {
            styleOverrides: {
                root: {
                    '& .RaBreadcrumb-list li': {
                        color: colors.textSecondary
                    },
                    '& .RaBreadcrumb-list li+li:last-child': {
                        color: colors.textPrimary
                    }
                },
            },
        },
        /*
        RaAutocompleteInput: {
          styleOverrides: {
            root: {
              '& .MuiOutlinedInput-root .MuiAutocomplete-input': {
                paddingTop: '0px',
                paddingBottom: '0px'
              },
              '&  .MuiInputLabel-root': {
                transform: 'translate(14px, 9px) scale(1)'
              },
              '& .MuiInputLabel-shrink': {
                transform: 'translate(14px, -9px) scale(0.75)'
              }

            }
          },
        },*/

        MuiTextField: {
            defaultProps: {
                variant: 'outlined',
                fullWidth: true,
            },
        },

        MuiSelect: {
            defaultProps: {
                variant: 'outlined',
            },
        },
        MuiFormControl: {
            defaultProps: {
                variant: 'outlined',
                fullWidth: true,
            },
        },
        MuiInputLabel: {
            styleOverrides: {

                root: {
                    color: '#737389'
                },
            },
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    backgroundColor: 'transparent !important',

                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: '#737389',
                    }
                },
                'input': {
                    backgroundColor: 'transparent !important',
                    color: 'white'
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    backgroundColor: colors.primary
                }
            }
        },
        MuiTabs: {
            styleOverrides: {
                indicator: {
                    backgroundColor: colors.primary
                }
            }
        },

        MuiTab: {
            styleOverrides: {
                root: {
                    color: colors.textPrimary,
                    '&:hover': {
                        color: colors.primary
                    }
                },
                selected: {
                    color: colors.primary,
                    '&:hover': {
                        color: colors.primary,
                    }
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: colors.textSecondary,

                },
            }
        },
        MuiTypography: {
            styleOverrides: {
                root: {
                    color: colors.textPrimary,
                },
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    color: colors.textPrimary,
                    borderBottom: '1px solid #2D2D37',
                    '> a': {
                        color: colors.primary,
                    }
                }
            }
        },
        RaReferenceField: {
            styleOverrides: {
                root: {
                    color: colors.primary,
                    textDecoration: 'underline'
                }
            }
        },
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    '&.Mui-active': {
                        color: colors.textPrimary
                    }
                }
            }
        },
        RaDatagrid: {
            styleOverrides: {
                root: {
                    '& .RaDatagrid-headerCell': {
                        backgroundColor: '#2D2D37'
                    },
                    '& .RaDatagrid-expandIcon': {
                        color: colors.textPrimary,
                    },
                    '& .RaDatagrid-row': {
                        backgroundColor: 'transparent'
                    },
                }
            }
        },
        RaList: {
            styleOverrides: {
                root: {
                    '& .RaList-content': {
                        backgroundColor: 'transparent'
                     }
                }
            }
        },
        MuiTablePagination:{
            styleOverrides: {
                root: {
                    color: colors.textSecondary
                }
            }
},
        MuiPaginationItem: {
            styleOverrides: {
                root: {
                    color: colors.textPrimary,
                    '&.Mui-selected': {
                        backgroundColor: colors.primary,
                        color: colors.textDark
                    },
                    '&:hover': {
                      backgroundColor: 'rgba(243, 206, 123, 0.5)'
                    },
                }
            }
        },
        RaFilterFormInput: {
            styleOverrides: {
                root: {
                    '& .RaFilterFormInput-hideButton svg': {
                        color: colors.textSecondary,
                    },
                    '& .MuiOutlinedInput-notchedOutline': {
                        borderColor: colors.borderBg,
                    }
                }
            },
        },

        RaResettableTextField: {
            styleOverrides: {
                root: {
                    '& .RaResettableTextField-clearButton': {
                        color: colors.textSecondary,
                    }
                }
            },
        },
        RaSearchInput: {
            styleOverrides: {
                root: {
                    '& svg': {
                        color: colors.textSecondary,
                    }
                }
            },
        },
        MuiAutocomplete: {
            styleOverrides: {
                root: {
                    '& .MuiAutocomplete-endAdornment svg': {
                        color: colors.textSecondary,
                    }
                }
            },
        },
        RaSelectArrayInput: {
            styleOverrides: {
                root: {
                    '& svg': {
                        color: colors.textSecondary,
                    }
                }
            },
        },
        RaSaveButton: {
            styleOverrides: {
                root: {
                    background: 'linear-gradient(92.9deg, #AC6CDB 6.53%, #586DE4 73.62%)',
                },
            },
        },
        MuiButton: {
            styleOverrides: {

                containedPrimary: {
                        background: 'linear-gradient(92.9deg, #AC6CDB 6.53%, #586DE4 73.62%) !important',

                    }

            },
        },

    }
}

export const lightTheme = {
    ...darkTheme,
    palette: {
        ...darkTheme.palette,
        type: 'light' as 'light',
    }
}
