import * as React from 'react'
import {FC} from 'react'
import {
    List,
    ListProps, EditButton,
} from 'react-admin'
import EmptyList from 'src/components/list/EmptyList'
import TreeDatagrid from 'src/components/list/tree/TreeDatagrid'
import TransTextField from 'src/components/fields/TransTextField'
import {ModalEditButton} from 'src/components/Modal/ModalButton'
import {BackgroundCategoryChangeParentForm} from 'src/resources/background-category/BackgroundCategoryChangeParentForm'


const BackgroundCategoryList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={false}
            title="Категории фонов"
            filter={{'translations.languageCode': 'ru'}}
            sort={{field: 'sort', order: 'ASC'}}
            empty={<EmptyList title={'Нет'} description={'Вы можете добавить'}
                              buttonText={'Добавить'}/>}
        >
            <TreeDatagrid>
                <TransTextField source="name" label=""/>
                <EditButton label={'Редакировать'}/>
                <ModalEditButton label={'Переместить'} modal={<BackgroundCategoryChangeParentForm/>}/>

            </TreeDatagrid>
        </List>
    )
}

export default BackgroundCategoryList
