import slugify from 'slugify'
const PNF = require('google-libphonenumber').PhoneNumberFormat
const phoneUtil = require('google-libphonenumber').PhoneNumberUtil.getInstance()
import { formatRelative } from 'date-fns'
import { ru } from 'date-fns/locale'
import {getTranslation} from 'src/utils/translation'

const pluralizeNative = require('numeralize-ru').pluralize

export default class Formatter {
  static pluralize(number: number, word1: string, word2: string, word3: string) {
    return pluralizeNative(number, word1, word2, word3)
  }
  static slug = (val: string) => val ? slugify(val)?.replace(/[`~!@#$%^&*()|+=?;:'",.<>\{\}\[\]\\\/]/gi, '')?.toLowerCase()?.trim() || '' : ''

  static cleanPhone = (phone: string) => {
    if (phone) {
      let phoneCleaned = phone.replace(/[^\+0-9]/g, '')
      if (!phoneCleaned.startsWith('+')) {
        phoneCleaned = '+' + phoneCleaned
      }
      return phoneCleaned
    }
    return phone
  }

  static formatDateRelative(date: string | Date) {
    const formatRelativeLocale: {[key: string]: string} = {
      'yesterday': 'Вчера в HH:mm',
      'today': 'Сегодня в HH:mm',
      'other': 'dd MMMM yyyy HH:mm', // Difference: Add time to the date
    }

    const locale = {
      ...ru,
      formatRelative: (token: string) =>
          formatRelativeLocale[token] || formatRelativeLocale['other'],
    }
    if (!date) {
      return ''
    }
    return formatRelative(typeof  date === 'string' ? new Date(date) : date, new Date(), { locale })
  }
  static formatDateUntil(date: string | Date) {
    const formatRelativeLocale: {[key: string]: string} = {
      'yesterday': 'Вчера до HH:mm',
      'today': 'до HH:mm',
      'tomorrow': 'Завтра до HH:mm',
      'other': 'до dd.MM.yyyy HH:mm', // Difference: Add time to the date
    }

    const locale = {
      ...ru,
      formatRelative: (token: string) =>
          formatRelativeLocale[token] || formatRelativeLocale['other'],
    }
    if (!date) {
      return ''
    }
    return formatRelative(typeof  date === 'string' ? new Date(date) : date, new Date(), { locale })
  }

  static pad(pad: string, str: string, padLeft = true) {
    if (typeof str === 'undefined') return pad
    if (padLeft) {
      return (pad + str).slice(-pad.length)
    } else {
      return (str + pad).substring(0, pad.length)
    }
  }

  static formatNumber(num: number, separator?: string) {
    return num?.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ')
  }
  static formatPrice = (price: number, suffix?: string) => {
    return `${this.formatNumber(parseFloat(price.toFixed(2)))} ${suffix ?? '$'}`
  }


  static formatPhone (phone: string) {
    try {
      const number = phoneUtil.parseAndKeepRawInput(phone, 'RU')
      return phoneUtil.format(number, PNF.INTERNATIONAL)
    }catch (e){
      console.error(e)
      return phone
    }
  }

  static formatDataWithTranslation = ({fields, data, langCode}: {fields: string[], data: any, langCode: string}) => {
    const translations = data.translations || []
    let currentTranslationIndex = translations.findIndex((i: any) => i.languageCode === langCode)
    if(currentTranslationIndex === -1) {
      currentTranslationIndex = 0
      translations.push({languageCode: langCode})
    }
    const newData = {...data}
    for (const i of fields) {
      translations[currentTranslationIndex][i] = newData[i] || ''

      delete newData[i]
    }
    return {...newData, translations}
  }

  static formatUserName (record: any){
    return  this.formatUserFirstLastName(record)
  }
  static formatUserFirstLastName(record: any) {
    return  `${ (record?.firstName || record?.lastName) ? ` ${record?.lastName || ''}${record?.firstName ? ` ${record?.firstName }` : ''}${record?.patronymic ? ` ${record?.patronymic }` : ''}` : ''}`
  }
  static formatProfileName (record: any) {
    if(record?.company){
      return record.company
    }
    return this.formatUserName(record)
  }
  static formatProfileUserName (record: any) {

    return this.formatUserName(record)
  }
  static formatJson(json: string) {
    if(!json){
      return
    }
    try{

      return JSON.parse(json)
    }catch (e){
      return null
    }
  }
  static formatCvName(cv: any){
    const category =  cv.categories.length > 0 ? cv.categories[0] : null
    return category ? getTranslation(category).name : null
  }
}
export const isString = (thing: any) => Object.prototype.toString.call(thing) === '[object String]'


