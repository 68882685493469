import * as React from 'react'
import {FC} from 'react'
import {
  Create,
  EditProps,
} from 'react-admin'
import CommunityForm from 'src/resources/community/CommunityForm'

interface TitleProps {
  record?: any;
}

const Title = (props: TitleProps) =>
 <span>Новая Сообщество</span>

const CommunityCreate: FC<EditProps> = props => {
  return (
    <Create {...props} redirect="list"  title={<Title/>}>
      <CommunityForm/>
    </Create>
  )
}

export default CommunityCreate