import PersonIcon from '@mui/icons-material/Person'
import PushList from './PushList'
import PushEdit from './PushEdit'
import PushCreate from './PushCreate'

export default {
  create: PushCreate,
  edit: PushEdit,
  list: PushList,
  icon: PersonIcon,
}