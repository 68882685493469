import * as React from 'react'
import {FC} from 'react'
import {
  Show,
  TabbedShowLayout,
  DateField,
  Tab,
  ArrayField,
  SingleFieldList,
  EditProps,
  FunctionField,
  Button,
  TextField, SelectField, TopToolbar, EditButton,
} from 'react-admin'
import {VacancyApplicationList} from 'src/resources/vacancy/VacancyApplicationList'
import VisibilityField from 'src/components/fields/VisibilityField'
import CustomChipField from 'src/components/fields/CustomChipField'
import {getTranslation} from 'src/utils/translation'
import PhoneField from 'src/components/fields/PhoneField'
import EmailField from 'src/components/fields/EmailField'
import {CompensationList} from 'src/types/enum/Compensation'
import {ModerationRejectReasonList} from 'src/types/enum/ModerationRejectReason'
import {ModerationStatusList} from 'src/types/enum/ModerationStatus'
import {ExperienceList} from 'src/types/enum/Expirience'
import {ScheduleList} from 'src/types/enum/Schedule'
import {EmploymentList} from 'src/types/enum/Employment'
import {LanguageLevelList} from 'src/types/enum/LanguageLevel'
import {ModalButton} from 'src/components/Modal/ModalButton'
import {VacancyStatusModal} from 'src/resources/vacancy/VacancyStatusModal'
import Link from '@mui/material/Link'
import {useRecordContext} from 'ra-core'


const Actions = () => {
  const recordContext = useRecordContext()
  return (
      <TopToolbar>
        <Button component={Link}  href={`https://arbihunter.com/vacancy/${recordContext?.id}`} label={'На сайте'} />
        <EditButton/>
        <ModalButton label={'Модерация'} modal={<VacancyStatusModal/>}/>
       </TopToolbar>
  )
}
interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Вакансия #{record?.id} ${record?.name}</span>

const VacancyShow: FC<EditProps> = props => {
  return (<Show {...props} title={<Title/>} actions={<Actions/>}>
    <TabbedShowLayout>
      <Tab label="Информация">
        <TextField source={'id'} label={'ID'}/>
        <VisibilityField source={'published'} label={'Опубликован'}/>
        <SelectField source={'moderationStatus'} label={'Статус модерации'} choices={ModerationStatusList}/>
        <SelectField source={'moderationRejectReason'} label={'Причины отказа модерации'} choices={ModerationRejectReasonList}/>

        <TextField source={'name'} label={'Имя'}/>
        <TextField source={'description'} label={'Описание'}/>
        <ArrayField source="categories"  label={'Категории'}>
          <SingleFieldList>
            <CustomChipField render={(i: any) => `${getTranslation(i)?.name}`}/>
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="skills"  label={'Скиллы'}>
          <SingleFieldList>
            <CustomChipField render={(i: any) => i.title}/>
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="sources"  label={'Источники'}>
          <SingleFieldList>
            <CustomChipField render={(i: any) => i.title}/>
          </SingleFieldList>
        </ArrayField>
        <TextField source={'salary'} label={'Зарплата'}/>
        <TextField source={'commission'} label={'Коммисия'}/>
        <SelectField source={'compensation'} label={'Вид оплаты'} choices={CompensationList}/>
        <TextField source={'currency'} label={'Валюта'}/>
        <SelectField source={'experience'} label={'Опыт'} choices={ExperienceList}/>
        <SelectField source={'schedule'} label={'График работы'} choices={ScheduleList}/>
        <SelectField source={'employment'} label={'Тип найма'} choices={EmploymentList}/>
        <ArrayField source="languageKnowledges"  label={'Языки'}>
          <SingleFieldList>
            <CustomChipField render={(i: any) => `${i.language} - ${LanguageLevelList.find((a: any) => a.id === i.level)?.name}`}/>
          </SingleFieldList>
        </ArrayField>
        <TextField source={'contactName'} label={'Контакты'}/>
        <TextField source={'telegramNickname'} label={'Ник ТГ'}/>
        <TextField source={'country.name'} label={'Страна'}/>
        <TextField source={'city.name'} label={'Город'}/>
        <PhoneField source={'phone'} label={'Телефон'}/>
        <EmailField source={'email'} label={'Email'}/>

        <FunctionField source={'isTop'} label={'Вип'} render={(i: any) => `${i.isTop ? 'Активирован' : 'Не активен'}`}/>
        <FunctionField source={'isRemote'} label={'Место'} render={(i: any) => `${i.isRemote ? 'Удаленно' : 'В офисе'}`}/>
        <DateField source={'schedulePublishAt'} label={'Опубликовать'} showTime={true} />
        <DateField source={'topPurchasedAt'} label={'Время покупки вип'} showTime={true} />
        <DateField source={'createdAt'} label={'Регистрация'} showTime={true} />
      </Tab>
      <Tab label="Отклики" path="application">
        <VacancyApplicationList />
      </Tab>
    </TabbedShowLayout>
  </Show>)
}

export default VacancyShow
