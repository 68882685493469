import ListIcon from '@mui/icons-material/List'
import CourseList from 'src/resources/course/CourseList'
import CourseEdit from 'src/resources/course/CourseEdit'
import CourseCreate from 'src/resources/course/CourseCreate'

export default {
  create: CourseCreate,
  edit: CourseEdit,
  list: CourseList,
  icon: ListIcon,
}