import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,
    Datagrid, SearchInput,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import TransTextField from 'src/components/fields/TransTextField'

const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'name': 'Имя',
            'shortDescription': 'Короткое Описание',
            'fullDescription': 'Описание',
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'Course'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>
            <SearchInput source="name:$contL" variant={'outlined'}  alwaysOn={true} resettable={true}/>

        </Filter>
    )
}

const CourseList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title="Курсы"
            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'Нет'} description={'Вы можете добавить'}
                              buttonText={'Добавить'}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <VisibilityField source="published" label={'П'}/>

                <IdShowButtonField source={'id'} label={'ID'}/>
                <TransTextField source={'name'} label={'Название'} />


            </Datagrid>
        </List>
    )
}

export default CourseList
