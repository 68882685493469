import * as React from 'react'
import { TextInput, SimpleForm,
} from 'react-admin'

const VerticalForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <TextInput source="title" label={'Название'} fullWidth variant={'outlined'}/>

    </SimpleForm>
  )
}
export default VerticalForm
