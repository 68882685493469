import * as React from 'react'

import {
  TabbedForm,
  FormTab,
  ArrayInput,
  TextInput,
  SelectInput,
  RadioButtonGroupInput,
  SimpleFormIterator, required, BooleanInput, FormDataConsumer, useResourceContext,
} from 'react-admin'
import {useFormContext} from 'react-hook-form'
import {ArticleCategoryType, ArticleCategoryTypeList} from 'src/types/interfaces/IArticle'
import {MediaInput} from 'src/components/inputs/MediaInput'
import {useMemo} from 'react'
import {LangList} from 'src/types'

const InfoTab = (props: any) => {
  const form = useFormContext()
  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>
    <BooleanInput source="published" label={'Опубликовано'} variant={'outlined'}/>

    {!props.categoryType && <RadioButtonGroupInput source={'categoryType'} label={'Тип'} choices={ArticleCategoryTypeList} variant={'outlined'} validate={required()} fullWidth={true}/>}
    <TextInput source="sort" label={'Сортировка'} variant={'outlined'}/>
     <ArrayInput source="translations" label={'Контент'}>
      <SimpleFormIterator fullWidth>
        <SelectInput source="languageCode" label={'Язык'} variant={'outlined'} choices={LangList} validate={required()} />
        <TextInput source="name" label={'Название'} variant={'outlined'} fullWidth validate={required()} />
      </SimpleFormIterator>
    </ArrayInput>
    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.categoryType !== ArticleCategoryType.CourseCompilation  && (
          <TextInput source="color" label={'Цвет'} variant={'outlined'}/>
      )}
    </FormDataConsumer>
    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.categoryType === ArticleCategoryType.CourseCompilation  && (
          <TextInput source="color" label={'Цвет'} variant={'outlined'}  />
      )}
    </FormDataConsumer>
    <MediaInput source={'image'} label={'Картинка'}
                accept={['image/jpeg', 'image/png']}/>

  </FormTab>)
}
const ArticleCategoryForm = (props: any) => {
  const res = useResourceContext()

  const categoryType = useMemo<ArticleCategoryType | null>(() => {
    switch(res){
      case 'article-category-knowledge':
        return ArticleCategoryType.Knowledge
      case 'article-category-news':
        return ArticleCategoryType.News
      case 'course-compilation-category':
        return ArticleCategoryType.CourseCompilation
      case 'course-category':
        return ArticleCategoryType.Course

    }
    return null
  }, [res])
  return (
    <TabbedForm {...props} redirect={'list'} defaultValues={{categoryType}} categoryType={categoryType}>
      <InfoTab/>
    </TabbedForm>
  )
}
export default ArticleCategoryForm
