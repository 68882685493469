import * as React from 'react'
import { SimpleForm, ReferenceInput, required, SelectInput, TextInput, ArrayInput, SimpleFormIterator,
} from 'react-admin'
import {getTranslation} from 'src/utils/translation'
import {MediaInput} from 'src/components/inputs/MediaInput'
import {LangList} from 'src/types'

const BackgroundCategoryForm = (props: any) => {
  return (
      <SimpleForm {...props} redirect={'list'}>
        {props.isNew &&  <ReferenceInput  source="parentId" reference="background-category"  variant={'outlined'}
                                          filter={{'translations.languageCode': 'ru'}}
                                          sort={{field: 'translations.name', order: 'ASC'}} perPage={100}  validate={[required()]}>
          <SelectInput  fullWidth={true} resettable={true}  label="Категория"
                        optionText={(record) => `${getTranslation(record)?.name}`} />
        </ReferenceInput>}

        <ArrayInput source="translations" label={'Названия'}>
          <SimpleFormIterator fullWidth>
            <SelectInput source="languageCode" label={'Язык'} variant={'outlined'} choices={LangList} validate={required()} />
            <TextInput source="name" label={'Название'} variant={'outlined'} fullWidth validate={required()} />
          </SimpleFormIterator>
        </ArrayInput>
        <TextInput source={'key'} label={'Ключ'} />
        <TextInput source={'sort'} label={'Сортировка'} />
        <MediaInput source={'image'} label={'Rive или картинка'}   accept={['*/*']}/>
      </SimpleForm>
  )
}
export default BackgroundCategoryForm
