import React from 'react'
import {CommonInputProps} from 'react-admin'
import StarterKit from '@tiptap/starter-kit'
import Underline from '@tiptap/extension-underline'
import Link from '@tiptap/extension-link'
import TextAlign from '@tiptap/extension-text-align'
import {
  RichTextInput as RaRichTextInput,
  RichTextInputToolbar,
  FormatButtons,
  ListButtons,
  LinkButtons,
  QuoteButtons,
  ClearButtons,
} from 'ra-input-rich-text'
import {RichTextInputImageButton} from 'src/components/inputs/RichTextInput/RichTextInputImageButton'
import {Image} from '@tiptap/extension-image'
import {RichTextInputPreviewButton} from 'src/components/inputs/RichTextInput/RichTextInputPreviewButton'
import {Video} from 'src/components/inputs/RichTextInput/extensions/video'
import {RichTextInputVideoButton} from 'src/components/inputs/RichTextInput/RichTextInputVideoButton'
import {Figure} from 'src/components/inputs/RichTextInput/extensions/figure'
import {CustomLevelSelect} from 'src/components/inputs/RichTextInput/extensions/CustomLevelSelect'


export interface Props extends CommonInputProps{

}

export const RichTextInput = (props: Props) => {
  const formatHtml = (_html : string) => {
    const html = _html
    return html?.replaceAll(/<li><p>(.*?)<\/p><(\/?)(ol|li|ul)>/gi, '<li>$1<$2$3>')
  }
  return (<RaRichTextInput
      editorOptions={{
        extensions: [
          StarterKit,
          Underline,
          Link,
          TextAlign.configure({
            types: ['heading', 'paragraph'],
          }),
          Image.configure({
            //inline: true,
          }),
          Video.configure(),
          Figure,

        ],
      }}
      format={formatHtml}
      toolbar={
        <RichTextInputToolbar>
          <CustomLevelSelect size={'small'} />
          <FormatButtons />
          <ListButtons />
          <LinkButtons />
          <QuoteButtons />
          <ClearButtons />
          <RichTextInputImageButton/>
          <RichTextInputVideoButton/>
          <RichTextInputPreviewButton/>
        </RichTextInputToolbar>
      }
      {...props}
  />)
}
