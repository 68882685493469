import * as React from 'react'
import {
  TabbedForm,
  FormTab, ArrayInput, SimpleFormIterator, TextInput, required, SelectInput,
} from 'react-admin'
import {MediaInput} from 'src/components/inputs/MediaInput'
import {LangList} from 'src/types'

const InfoTab = (props: any) => {
  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>
    <MediaInput source={'image'} label={'Картинка'} />
    <TextInput source="key" helperText={'Ключ для Rive анимации'} label={'Ключ'} variant={'outlined'} fullWidth />
    <TextInput source={'sort'} label={'Сортировка'} />
    <ArrayInput source="translations" label={'Контент'}>
      <SimpleFormIterator fullWidth>
        <SelectInput source="languageCode" label={'Язык'} variant={'outlined'} choices={LangList} validate={required()} />
        <TextInput source="name" label={'Название'} variant={'outlined'} fullWidth validate={required()} />
      </SimpleFormIterator>
    </ArrayInput>
    </FormTab>)
}
const CharacterItemCategoryForm = (props: any) => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
    </TabbedForm>
  )
}
export default CharacterItemCategoryForm
