import * as React from 'react'
import {ChangeEventHandler} from 'react'
import {
  ArrayInput,
  BooleanInput,
  DateTimeInput, FormDataConsumer,
  FormTab,
  NumberInput,
  ReferenceInput,
  required,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
  useResourceContext,
} from 'react-admin'
import {MediaInput} from 'src/components/inputs/MediaInput'
import {useFormContext} from 'react-hook-form'
import {getTranslation} from 'src/utils/translation'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import { ArticleType, ArticleTypeList} from 'src/types/interfaces/IArticle'
import Formatter from 'src/utils/formatters'


const SeoTab = (props: any) => {
  return (<FormTab
    label='SEO'
    path={'seo'}
    {...props}>
    <TextInput source="seoTitle" label={'SEO title'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="seoKeywords" label={'SEO keywords'} multiline={true} variant={'outlined'} fullWidth={true}/>
    <TextInput source="seoDescription" label={'SEO description'} multiline={true} variant={'outlined'} fullWidth={true}/>
  </FormTab>)
}


const InfoTab = (props: any) => {
  const form = useFormContext()
  const resource = useResourceContext()
  const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    if (props.record?.id) {
      return
    }
    form.setValue('slug', Formatter.slug(e.currentTarget.value))
  }

  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>
    {!props.articleType && <SelectInput source="articleType" label={'Тип статьи'} variant={'outlined'} choices={ArticleTypeList}/>}

    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.articleType === ArticleType.CourseNews  && (
          <ReferenceInput  source="courseId" reference="course"  variant={'outlined'}
                           filter={{'translations.languageCode': 'ru'}}
                           sort={{field: 'translations.name', order: 'ASC'}} perPage={100}  validate={[required()]}>
            <SelectInput  fullWidth={true} validate={required()}  label="Курс"
                          optionText={(record) => `${getTranslation(record)?.name}`} />
          </ReferenceInput>
      )}
    </FormDataConsumer>
    <BooleanInput source="published" label={'Опубликовано'} variant={'outlined'}/>
    <FormDataConsumer fullWidth={true} variant={'outlined'}>
      {({ formData, ...rest }) => formData.articleType !== ArticleType.CourseNews  && (
          <ReferenceInput  source="articleCategoryId" reference="article-category"  variant={'outlined'}
                           filter={{'translations.languageCode': 'ru', categoryType: resource === 'article-news' ? ArticleType.News : ArticleType.Knowledge}}
                           sort={{field: 'translations.name', order: 'ASC'}} perPage={100}  validate={[required()]}>
            <SelectInput  fullWidth={true} validate={required()}  label="Категория"
                          optionText={(record) => `${getTranslation(record)?.name}`} />
          </ReferenceInput>
      )}
    </FormDataConsumer>


    <TextInput source={'name'} label={'Название'} onChange={handleNameChange}  variant={'outlined'} validate={required()} fullWidth={true}/>
    <BooleanInput source={'isTop'} label={'Топ'}  variant={'outlined'}  fullWidth={true}/>

    <DateTimeInput
      label="Время публикации"
      source="publishedAt"
      variant={'outlined'}
      fullWidth={true}
    />

    <MediaInput source={'image'} label={'Картинка'} />
    <MediaInput isMulti source={'images'} label={'Несколько картинок'} />
       <ArrayInput source="blocks" label={'Текст блоки'}>
        <SimpleFormIterator fullWidth>
          <TextInput source="title"  label={'Заголовок'} fullWidth/>
          <RichTextInput source={'body'} label={'Текст'} variant={'outlined'} validate={required()} fullWidth={true}/>
        </SimpleFormIterator>
      </ArrayInput>
    <NumberInput source={'minutesToRead'} multiline={true} label={'Минут на чтение'} variant={'outlined'}  fullWidth={true}/>

  </FormTab>)
}

export const ArticleForm = (props: any) => {
  const resource = useResourceContext()
  const getDefaultType = () => {
    switch (resource){
      case 'article-news':
        return ArticleType.News
      case 'article-knowledge':
        return ArticleType.Knowledge
      case 'article-course':
        return ArticleType.Course
      case 'article-course-news':
        return ArticleType.CourseNews
    }
  }
  return (
    <TabbedForm {...props} redirect={'list'} defaultValues={{articleType: getDefaultType()}} articleType={getDefaultType()}>
      <InfoTab/>
    </TabbedForm>
  )
}
