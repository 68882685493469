import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,
    Datagrid, SearchInput, TextField, DateField,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'
import AssetField from 'src/components/fields/AssetField'
import StoryBlockLinkField from 'src/components/fields/StoryBlockLinkField'
import StoryInput from 'src/components/inputs/StoryInput'

const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'StoryBlock'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>
            <SearchInput source="title:$contL" variant={'outlined'} alwaysOn/>
            <StoryInput source={'storyId'} label='Блок' alwaysOn/>
            <DateRangeFilterInput source={'publishedAt:$between'} label="Опубликовано"
            />

        </Filter>
    )
}

const StoryBlockList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title="Истории"
            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'Нет'} description={'Вы можете добавить'}
                              buttonText={'Добавить'}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField source={'id'} label={'ID'}/>
                <StoryBlockLinkField source={'story'} label={'Блок'}/>
                <TextField source={'title'} label={'Название'}/>
                <AssetField source={'image'} label={'Файл'}/>
                <DateField source="publishedAt" label={'Время публикации'} showTime={true}/>

            </Datagrid>
        </List>
    )
}

export default StoryBlockList
