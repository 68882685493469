import * as React from 'react'
import {RecordContextProvider, useDataProvider, useRefresh} from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import {FieldValues} from 'react-hook-form'
import {ArticleType} from 'src/types/interfaces/IArticle'
import {CourseArticleEditForm} from 'src/resources/course/article/CourseArticleEditForm'
interface Props{
    refresh?: boolean
    isShown?: boolean
    id?: number
    mainRecord: Record<any, any>
    onClose?: () => void
}
export const CourseArticleCreateModal = (props: Props) => {
    const refresh = useRefresh()
    const dataProvider = useDataProvider()
    const handleSubmit = async (data: FieldValues) => {
        console.log('HandleSubmit', data)
        const res = await dataProvider.create('course-news', {data: {...data,articleType: ArticleType.CourseNews, courseId: props.mainRecord.id}})
        if(props.refresh) {
            refresh()
        }
        if(props.onClose){
            props.onClose()
        }
        return true
    }

    return <RecordContextProvider value={{}}>
        <ModalForm
        isShown
        defaultValues={{preview: 'medium'}}
        fullWidth={true}
        maxWidth={'lg'}
        resource={'area'}
        title={'Новая новость'}
        onClose={props.onClose}
        save={handleSubmit}
    >
        {<CourseArticleEditForm refresh/>}
    </ModalForm>
    </RecordContextProvider>
}
CourseArticleCreateModal.defaults = {
    refresh: true
}
