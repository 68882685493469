import React, {ComponentProps, ReactElement} from 'react'
import {useInput, FieldTitle} from 'ra-core'
import {InputHelperText} from 'ra-ui-materialui'
import {
    FormHelperText,
    FormControl,
    InputLabel,
} from '@mui/material'
import PropTypes from 'prop-types'
import DateTimePicker from 'react-time-picker'
import styles from './index.module.scss'

export interface TimeInputProps {
    label?: string | ReactElement;
    source: string;
    fullWidth?: boolean;

    helperText?: ComponentProps<typeof InputHelperText>['helperText'];
    record?: Record<any, any>;
    resource?: string;
    variant?: string;

    [key: string]: any;
}

const TimeInput = (props: TimeInputProps) => {
    const {
        options = {}, // Quill editor options
        toolbar = true,
        fullWidth = true,
        classes: classesOverride,
        configureQuill,
        helperText,
        label,
        source,
        resource,
        variant,
        margin = 'dense',
        ...rest
    } = props

    const {
        id,
        isRequired,
        field: {value, onChange},
        fieldState: {error, isTouched},
    } = useInput({source, ...rest})


    return (<div>
            <FormControl
                error={!!(isTouched && error)}

                className="ra-rich-text-input"
                margin={margin}
            >
                <InputLabel shrink htmlFor={id}>
                    <FieldTitle
                        label={label}
                        source={source}
                        resource={resource}
                        isRequired={isRequired}
                    />
                </InputLabel>

                <DateTimePicker
                    {...props}
                    value={value}
                    disableClock={true}
                    className={styles.picker}
                    onChange={(value: any) => {
                        console.log('onChange', value)
                        if (!value) {
                            onChange(null)
                            return
                        }
                        try {

                            onChange((value))
                        } catch (e) {
                            console.error(e)
                        }
                    }}

                />
                <FormHelperText
                    error={!!error}
                    className={!!error ? 'ra-rich-text-input-error' : ''}
                >
                    <InputHelperText
                        error={error as any}
                        helperText={helperText || 'Можно также менять стрелками'}
                        touched={isTouched}
                    />
                </FormHelperText>
            </FormControl>
        </div>
    )
}

TimeInput.propTypes = {
    // @ts-ignore
    label: PropTypes.any,
    options: PropTypes.object,
    source: PropTypes.string,
    fullWidth: PropTypes.bool,
    configureQuill: PropTypes.func,
}

export default TimeInput