import ListIcon from '@mui/icons-material/List'
import VerticalList from 'src/resources/vertical/VerticalList'
import VerticalEdit from 'src/resources/vertical/VerticalEdit'
import VerticalCreate from 'src/resources/vertical/VerticalCreate'

export default {
  create: VerticalCreate,
  edit: VerticalEdit,
  list: VerticalList,
  icon: ListIcon,
}