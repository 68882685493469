import * as React from 'react'
import {
  TextField,
  SelectField,
} from 'react-admin'
import RelatedList from 'src/components/list/RelatedList'
import {useState} from 'react'
import {PersonalPageBlockTypeList} from 'src/types/enum/PersonalPageBlockType'

export const PersonalPageBlockList = (props: any) => {
  const record = props.record
  const data = record['blocks'] || []
  const [sort, setSort] = useState({field: 'id', order: 'ASC'})
  return (<RelatedList resource={'ProductOption'} record={record} data={data} >
    <SelectField source="type" label={'Тип'} choices={PersonalPageBlockTypeList}/>
    <TextField source="title" label={'Название'}/>
    <TextField source="text" label={'Код'}/>
  </RelatedList>)
}