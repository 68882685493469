import ListIcon from '@mui/icons-material/List'
import SourceList from 'src/resources/source/SourceList'
import SourceEdit from 'src/resources/source/SourceEdit'
import SourceCreate from 'src/resources/source/SourceCreate'

export default {
  create: SourceCreate,
  edit: SourceEdit,
  list: SourceList,
  icon: ListIcon,
}