import * as React from 'react'
import {FC} from 'react'
import {
  List,
  ListProps,
    EditButton,
  TextField,
  Pagination,
} from 'react-admin'
import TreeDatagrid from 'src/components/list/tree/TreeDatagrid'
import {ModalEditButton} from 'src/components/Modal/ModalButton'
import TransTextField from 'src/components/fields/TransTextField'
import {ServiceCategoryChangeParentForm} from 'src/resources/service-category/ServiceCategoryChangeParentForm'

const ServiceCategoryList: FC<ListProps> = props => {
  return (
    <List
      {...props}
      title="Категории"
      exporter={false}
      perPage={5000}
      pagination={<Pagination  rowsPerPageOptions={[5000]} />}
      filter={{'translations.languageCode': 'ru'}}
      sort={{field: 'sort', order: 'ASC'}}

    >
      <TreeDatagrid>
        <TransTextField source="name" label=""/>
        <TextField source="sort" label=""/>
        <EditButton label={'Редакировать'}/>
        <ModalEditButton label={'Переместить'} modal={<ServiceCategoryChangeParentForm/>}/>

      </TreeDatagrid>
    </List>
  )
}

export default ServiceCategoryList
