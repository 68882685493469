import * as React from 'react'
import {
  SimpleForm, TextInput,
} from 'react-admin'

const StopwordForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <TextInput source="word"  label={'Слово'} fullWidth variant={'outlined'}/>
    </SimpleForm>
  )
}
export default StopwordForm
