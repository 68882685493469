import * as React from 'react'
import {
  ArrayInput,
  FormDataConsumer,
  NumberInput,
  required,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput
} from 'react-admin'
import {PurchaseType, PurchaseTypeList} from 'src/types/enum/PurchaseType'
import {MediaInput} from 'src/components/inputs/MediaInput'
import {LangList} from 'src/types'


const TariffForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <SelectInput source="purchaseType" label={'Тип'}  choices={PurchaseTypeList} variant={'outlined'} fullWidth validate={required()} />
      <TextInput source="key" label={'Ключ'} variant={'outlined'} fullWidth validate={required()} />
      <NumberInput source="price" label={'Цена'} variant={'outlined'} fullWidth validate={required()} />
      <NumberInput source="period" label={'Период действия (Кол-во дней)'} variant={'outlined'} fullWidth  />
        <ArrayInput source="colors" label={'Цвета'}>
            <SimpleFormIterator inline>
                <TextInput source="." />
            </SimpleFormIterator>
        </ArrayInput>  <FormDataConsumer fullWidth={true} variant={'outlined'}>
        {({ formData, ...rest }) => [PurchaseType.contactsOpen, PurchaseType.addContactsOpen].includes(formData.purchaseType)  && (
            <NumberInput source="contactsCanOpenCount" label={'Кол-во Контактов для открытия'} variant={'outlined'} fullWidth validate={required()} />
        )}
      </FormDataConsumer>
      <MediaInput source={'icon'} label={'Иконка'}
                  accept={['image/jpeg', 'image/png', 'image/svg']}/>
       <ArrayInput source="translations" label={'Контент'}>
        <SimpleFormIterator fullWidth>
          <SelectInput source="languageCode" label={'Язык'} variant={'outlined'} choices={LangList} validate={required()} />
          <TextInput source="name" label={'Название'} variant={'outlined'} fullWidth validate={required()} />
          <TextInput source="description" label={'Описание'} multiline variant={'outlined'} fullWidth />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  )
}
export default TariffForm
