import * as React from 'react'
import {
  Pagination,
  DateField,
  Datagrid,
  TextField,
  ReferenceManyField, ReferenceField, SelectField, FunctionField,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import VisibilityField from 'src/components/fields/VisibilityField'
import TransTextField from 'src/components/fields/TransTextField'
import {ArticleTypeList} from 'src/types/interfaces/IArticle'

export const ArticleList = (props: any) => {
  const {record} = props
  return (
    <ReferenceManyField {...props} reference="article-news" target="communityId" addLabel={false}
                        sort={{field: 'id', order: 'DESC'}}  pagination={<Pagination/>} perPage={50}>
      <Datagrid>
        <IdShowButtonField source={'id'} label={'ID'}/>
        <VisibilityField source="published" label={'П'}/>
        <ReferenceField label="Категория" source="articleCategoryId" reference="article-category">
          <TransTextField source="name"/>
        </ReferenceField>
        <TextField source="name" label={'Название'}/>
        <SelectField source="articleType" label={'Тип'} choices={ArticleTypeList}/>
        <FunctionField source={'isTop'} label={'Топ'} render={(i: any) => i.isTop ? 'Да' : ''} />
        <DateField source="publishedAt" label={'Время публикации'} showTime={true}/>
        <TextField source="totalLikes" label={'Лайков'}/>
        <TextField source="totalViews" label={'Посмотров'}/>
      </Datagrid>
    </ReferenceManyField>)
}
