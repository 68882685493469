import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,
    DeleteButton,
    EditButton,
    Datagrid, SelectField, TextField, DateField, SearchInput, SelectInput,
} from 'react-admin'

import MenuItem from '@mui/material/MenuItem'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate, formatListValue} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import {ProfileTypeList} from 'src/types/interfaces/IProfile'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'
import {HirerRoles} from 'src/types/enum/HirerRole'
import ProfileField from 'src/components/fields/ProfileField'
import ProfileBalanceField from 'src/components/fields/ProfileBalanceField'
import Formatter from 'src/utils/formatters'
import {DropdownMenu} from 'src/components/DropdownMenu/DropdownMenu'

const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'id': 'ID',
            'profileType': 'Тип',
            'name': 'ФИО',
            'email': 'Email',
            'hirerRole': 'Роль',
            'balance': 'Баланс',
            'telegramNickname': 'ТГ',
            'company': 'Компания',
            'country.name': 'Страна',
            'city.name': 'Город',
            'phone': 'Телефон',
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,
            'name': Formatter.formatProfileUserName(item),
            'country.name': item.country?.name,
            'city.name': item.city?.name,
            balance: item?.user?.owner?.balance ?? item?.user?.balance,
            profileType: formatListValue(item.profileType, ProfileTypeList),
            hirerRole: formatListValue(item.hirerRole, HirerRoles),
            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'Profile'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>
            <SearchInput source="user.email:$contL&firstName:$contL&lastName:$contL&telegramNickname:$contL&company:$contL"  variant={'outlined'} alwaysOn={true}  />

            <DateRangeFilterInput source={'createdAt:$between'} label="Регистрация"
                                  alwaysOn/>
            <SelectInput source={'hirerRole'} label={'Роль'} choices={HirerRoles} variant={'outlined'}/>
        </Filter>
    )
}
const ActionFields = () => {
    return (<DropdownMenu>
        <MenuItem>
            <EditButton/>
        </MenuItem>
        <MenuItem>
        <DeleteButton mutationMode={'pessimistic'}/>
        </MenuItem>
    </DropdownMenu>)
}
const ProfileList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title="Профили"
            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'Нет профилей'} description={'Вы можете добавить профиль'}
                              buttonText={'Добавить профиль'}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField type={'show'} source={'id'} label={'ID'}/>
                <ActionFields/>
                <SelectField source={'profileType'} label={'Тип'} choices={ProfileTypeList}/>
                <ProfileField source={'lastName'} format={'userName'} label={'ФИО'}/>
                <TextField source={'email'} label={'Email'}/>
                <SelectField source={'hirerRole'} label={'Роль'} choices={HirerRoles}/>
                <ProfileBalanceField source={'user'} label={'Баланс'}/>
                <TextField source={'telegramNickname'} label={'Ник ТГ'}/>
                <TextField source={'company'} label={'Компания'}/>
                <TextField source={'country.name'} label={'Страна'}/>
                <TextField source={'city.name'} label={'Город'}/>
                <DateField source={'lastActivityAt'} label={'Активность'} showTime/>
                <DateField source={'createdAt'} label={'Регистрация'} showTime={true} />
            </Datagrid>
        </List>
    )
}

export default ProfileList
