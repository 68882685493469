import * as React from 'react'
import {FC} from 'react'
import {
  Create,
  EditProps,
} from 'react-admin'
import CharacterItemForm from 'src/resources/character-item/CharacterItemForm'

interface TitleProps {
  record?: any;
}

const Title = (props: TitleProps) =>
 <span>Новая Вещи</span>

const CharacterItemCreate: FC<EditProps> = props => {
  return (
    <Create {...props} redirect="list" title={<Title/>}>
      <CharacterItemForm/>
    </Create>
  )
}

export default CharacterItemCreate