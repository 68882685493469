import * as React from 'react'
import {
  Pagination,
  TextField,
  ReferenceManyField,
} from 'react-admin'
import EditListButtons from 'src/components/list/EditListButtons'
import RelatedList from 'src/components/list/RelatedList'
import {ModalCreateButton, ModalEditButton} from 'src/components/Modal/ModalButton'
import {useRecordContext} from 'ra-core'
import {CourseBlockCreateModal} from 'src/resources/course/block/CourseBlockCreateModal'
import {CourseBlockEditModal} from 'src/resources/course/block/CourseBlockEditModal'
import {CourseBlockStepList} from 'src/resources/course/step/CourseBlockStepList'
import TransTextField from 'src/components/fields/TransTextField'
import {getTranslation} from 'src/utils/translation'

interface Props {
  record?: any;
}
const Panel = () => {
    return (
        <CourseBlockStepList/>
    )
}
export const CourseBlockList = (props: Props) => {
   const record = useRecordContext()
  console.log('ListProps', record)
  return (

      <ReferenceManyField {...props} reference="course-block" target="courseModuleId"
                        sort={{field: 'order', order: 'ASC'}} pagination={<Pagination/>} perPage={50}>
        <RelatedList
            title={`Блоки модуля «${getTranslation(record as any)?.title}»`}
            record={record} bulkActionButtons={false} resource={'course-block'}   emptyTitle={'Нет блоков'} expand={<Panel/>}
                     actions={<ModalCreateButton modal={<CourseBlockCreateModal mainRecord={record} refresh/>}/>}
                     createButton={<ModalCreateButton modal={<CourseBlockCreateModal mainRecord={record} refresh/>}/>}>
            <TransTextField source={'title'} label={'Название'} />
            <TextField source={'order'} label={'Сортировка'}/>
        <EditListButtons editButton={<ModalEditButton modal={<CourseBlockEditModal mainRecord={record} refresh/>}/>}/>
        </RelatedList>
    </ReferenceManyField>)
}
