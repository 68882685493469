import * as React from 'react'
import {RecordContextProvider, useDataProvider, useRefresh} from 'react-admin'
import ModalForm from 'src/components/ModalForm'
import {FieldValues} from 'react-hook-form'
import {CourseBlockEditForm} from 'src/resources/course/block/CourseBlockEditForm'
interface Props{
    refresh?: boolean
    isShown?: boolean
    id?: number
    mainRecord: Record<any, any>
    onClose?: () => void
}
export const CourseBlockCreateModal = (props: Props) => {
    const refresh = useRefresh()
    const dataProvider = useDataProvider()
    const handleSubmit = async (data: FieldValues) => {
        console.log('HandleSubmit', data)
        const res = await dataProvider.create('course-block', {data: {...data, courseId: props.mainRecord.courseId, courseModuleId: props.mainRecord.id}})
        if(props.refresh) {
            refresh()
        }
        if(props.onClose){
            props.onClose()
        }
        return true
    }

    return <RecordContextProvider value={{}}>
        <ModalForm
        isShown
        defaultValues={{preview: 'medium'}}
        fullWidth={true}
        maxWidth={'lg'}
        resource={'area'}
        title={'Новый блок'}
        onClose={props.onClose}
        save={handleSubmit}
    >
        {<CourseBlockEditForm />}
    </ModalForm></RecordContextProvider>
}
CourseBlockCreateModal.defaults = {
    refresh: true
}
