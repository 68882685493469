import * as React from 'react'
import {
  SimpleForm, TextField,
} from 'react-admin'

const FeedbackForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <TextField source={'mark'} label={'Оценка'}/>
      <TextField source={'content'} label={'Отзыв'}/>
    </SimpleForm>
  )
}
export default FeedbackForm
