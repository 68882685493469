import * as React from 'react'
import {
  TabbedForm,
  FormTab, SelectInput, required, TextInput,
} from 'react-admin'
import {useFormContext} from 'react-hook-form'
import {ProfileTypeList} from 'src/types/interfaces/IProfile'

const InfoTab = (props: any) => {
  const form = useFormContext()
  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>
    <SelectInput source={'profileType'} label={'Тип'} choices={ProfileTypeList} disabled variant={'outlined'} validate={required()} />
    <TextInput source={'firstName'} label={'Имя'} variant={'outlined'} validate={required()}/>
    <TextInput source={'lastName'} label={'Фамилия'} variant={'outlined'} validate={required()}/>
    <TextInput source={'patronymic'} label={'Отчество'} variant={'outlined'} />
    <TextInput source={'telegramNickname'} label={'Ник ТГ'} variant={'outlined'} />
    <TextInput source={'company'} label={'Компания'} variant={'outlined'} />
    <TextInput source={'country.geoname'} label={'Страна'} variant={'outlined'} />
    <TextInput source={'city.geoname'} label={'Город'} variant={'outlined'} />
    </FormTab>)
}
const ProfileForm = (props: any) => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
    </TabbedForm>
  )
}
export default ProfileForm
