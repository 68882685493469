import * as React from 'react'
import {FC} from 'react'
import {
  Show,
  TabbedShowLayout,
  DateField,
  Tab,
  EditProps,
  TextField, SelectField,
} from 'react-admin'
import ProfileLinkField from 'src/components/fields/ProfileLinkField'
import Formatter from 'src/utils/formatters'
import CvLinkField from 'src/components/fields/CvLinkField'
import VacancyLinkField from 'src/components/fields/VacancyLinkField'
import {ProposalStatusList} from 'src/types/enum/ProposalStatus'

interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Приглашение #{record?.id} от {Formatter.formatUserName(record)}</span>

const ProposalShow: FC<EditProps> = props => {
  return (<Show {...props} title={<Title/>}>
    <TabbedShowLayout>
      <Tab label="Информация">
        <TextField source={'id'} label={'ID'}/>
        <SelectField source={'status'} label={'Статус'} choices={ProposalStatusList}/>
        <VacancyLinkField source={'vacancy'} label={'Вакансия'}/>
        <CvLinkField source={'cv'} label={'Резюме'}/>
        <ProfileLinkField label={'Профиль компании'} source={'profile'}/>
        <TextField source={'coverLetter'} label={'Сообщение'}/>
        <DateField source={'createdAt'} label={'Создан'} showTime/>
      </Tab>

    </TabbedShowLayout>
  </Show>)
}

export default ProposalShow
