import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,
    SelectField,
    Datagrid, DateField, SelectInput,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import ProfileLinkField from 'src/components/fields/ProfileLinkField'
import CvLinkField from 'src/components/fields/CvLinkField'
import VacancyLinkField from 'src/components/fields/VacancyLinkField'
import {ApplicationStatusList} from 'src/types/enum/ApplicationStatus'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'
import ProfileInput from 'src/components/inputs/ProfileInput'

const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'Application'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>
            <ProfileInput source={'profileId'} label={'Профиль'} alwaysOn resettable/>
            <ProfileInput source={'cvId'} label={'Резюме'} />
            <ProfileInput source={'vacancyId'} label={'Вакансия'} />
            <SelectInput source={'status'} label={'Статус'} choices={ApplicationStatusList} fullWidth variant={'outlined'}/>
            <DateRangeFilterInput source={'createdAt:$between'} label="Создано"
                                  alwaysOn/>
        </Filter>
    )
}

const ApplicationList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={false}
            filters={<_Filter/>}
            title="Отклики"
            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'Нет откликов'}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField type={'show'} source={'id'} label={'ID'}/>
                <SelectField source={'status'} label={'Статус'} choices={ApplicationStatusList}/>
                <VacancyLinkField source={'vacancy'} label={'Вакансия'}/>
                <CvLinkField source={'cv'} label={'Резюме'}/>
                <ProfileLinkField label={'Профиль'} source={'profile'}/>

                <DateField source={'createdAt'} label={'Создан'} showTime/>
            </Datagrid>
        </List>
    )
}

export default ApplicationList
