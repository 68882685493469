import * as React from 'react'
import {
    SimpleForm, TextInput,
} from 'react-admin'
import {MediaInput} from 'src/components/inputs/MediaInput'

const CourseAuthorForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <TextInput
          source="name"
          label={'Название'}
          fullWidth
          variant={'outlined'}
      />
      <TextInput
          source="description"
          label={'Описание'}
          fullWidth
          multiline={true}
          variant={'outlined'}
      />
      <MediaInput source={'image'} label={'Аватар'} />

    </SimpleForm>
  )
}
export default CourseAuthorForm
