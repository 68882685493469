import * as React from 'react'
import {FC} from 'react'
import {
  Create,
  EditProps,
} from 'react-admin'
import {PushForm} from 'src/resources/push/PushForm'
interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Новое Push Уведомление</span>

const PushCreate: FC<EditProps> = props => {
  return (
    <Create {...props}  title={<Title/>}>
      <PushForm/>
    </Create>
  )
}

export default PushCreate