import * as React from 'react'
import {
  Pagination,
  DateField,
  Datagrid,
  TextField,
  ReferenceManyField, SelectField, RaRecord, FieldProps,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {ProfileType, ProfileTypeList} from 'src/types/interfaces/IProfile'
import ProfileField from 'src/components/fields/ProfileField'
import {HirerRoles} from 'src/types/enum/HirerRole'
import {useRecordContext} from 'ra-core'


interface Props<RecordType extends RaRecord = any> extends FieldProps {
  type?: 'show' | 'edit'
}

const ProfileIdShowField = (props: Props) => {
  const record = useRecordContext()
  return <IdShowButtonField resource={record.profileType === ProfileType.Employee ? 'profile-employee' : 'profile-hirer'} type={'show'} source={'id'} label={'ID'}/>

}

export const CommunityUserList = (props: any) => {
  const {record} = props
  return (
    <ReferenceManyField {...props} reference="profile-by-community" target='communities.id' addLabel={false}
                        sort={{field: 'id', order: 'DESC'}}  pagination={<Pagination/>} perPage={50}>
      <Datagrid>
        <ProfileIdShowField type={'show'} source={'id'} label={'ID'}/>
        <SelectField source={'profileType'} label={'Тип'} choices={ProfileTypeList}/>
        <ProfileField source={'lastName'} format={'userName'} label={'ФИО'}/>
        <SelectField source={'hirerRole'} label={'Роль'} choices={HirerRoles}/>
        <TextField source={'telegramNickname'} label={'Ник ТГ'}/>
        <TextField source={'company'} label={'Компания'}/>
        <TextField source={'country.name'} label={'Страна'}/>
        <TextField source={'city.name'} label={'Город'}/>
        <DateField source={'lastActivityAt'} label={'Активность'} showTime/>
        <DateField source={'createdAt'} label={'Регистрация'} showTime={true} />
      </Datagrid>
    </ReferenceManyField>)
}
