import * as React from 'react'
import {FC} from 'react'
import {
  EditProps,
} from 'react-admin'
import CourseForm from 'src/resources/course/CourseForm'
import {CustomEdit} from 'src/components/CustomEdit'
import {useRecordContext} from 'ra-core'

interface TitleProps {
  record?: any;
}
const Title: FC<TitleProps> = () => {
  const record = useRecordContext()
 return  record ? <span>Курс {record.name}</span> : null
}
const CourseEdit: FC<EditProps> = props => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic" title={<Title/>}>
    <CourseForm isEdit/>
    </CustomEdit>
  )
}
export default CourseEdit
