import * as React from 'react'
import {FC} from 'react'
import {
  Create,
  EditProps,
} from 'react-admin'
import BackgroundForm from 'src/resources/background/BackgroundForm'

interface TitleProps {
  record?: any;
}

const Title = (props: TitleProps) =>
 <span>Новый Фон</span>

const BackgroundCreate: FC<EditProps> = props => {
  return (
    <Create {...props} redirect="list"  title={<Title/>}>
      <BackgroundForm/>
    </Create>
  )
}

export default BackgroundCreate
