import * as React from 'react'
import {
    Pagination,
    TextField,
    ReferenceManyField, ReferenceField, DateField,
} from 'react-admin'
import EditListButtons from 'src/components/list/EditListButtons'
import RelatedList from 'src/components/list/RelatedList'
import {ModalCreateButton, ModalEditButton} from 'src/components/Modal/ModalButton'
import {useRecordContext} from 'ra-core'
import {CourseBlockList} from 'src/resources/course/block/CourseBlockList'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import VisibilityField from 'src/components/fields/VisibilityField'
import {CourseArticleCreateModal} from 'src/resources/course/article/CourseArticleCreateModal'
import {CourseArticleEditModal} from 'src/resources/course/article/CourseArticleEditModal'

interface Props {
  record?: any;
}
const Panel = () => {
    return (
        <CourseBlockList/>
    )
}
export const CourseArticleList = (props: Props) => {
   const record = useRecordContext()
  console.log('ListProps', record)
  return (

      <ReferenceManyField {...props} reference="course-news" target="courseId"
                        sort={{field: 'order', order: 'ASC'}} pagination={<Pagination/>} perPage={50}>
        <RelatedList record={record} bulkActionButtons={false} resource={'course-module'}   emptyTitle={'Нет новостей'} expand={<Panel/>}
                     actions={<ModalCreateButton modal={<CourseArticleCreateModal mainRecord={record} refresh/>}/>}
                     createButton={<ModalCreateButton modal={<CourseArticleCreateModal mainRecord={record} refresh/>}/>}>
            <IdShowButtonField source={'id'} label={'ID'}/>
            <VisibilityField source="published" label={'П'}/>
            <TextField source="name" label={'Название'}/>
           <DateField source="publishedAt" label={'Время публикации'} showTime={true}/>
            <TextField source="totalLikes" label={'Лайков'}/>
            <TextField source="totalViews" label={'Посмотров'}/>
            <TextField source="order" label={'Сортировка'}/>
            <TextField source="orderCategory" label={'Сортировка в кат.'}/>
            <ReferenceField label="Админ" source="adminId" reference="admin">
                <TextField source="name"/>
            </ReferenceField>
            <DateField source="updatedAt" label={'Обновлено'} showTime={true}/>
        <EditListButtons editButton={<ModalEditButton modal={<CourseArticleEditModal mainRecord={record} refresh/>}/>}/>
        </RelatedList>
    </ReferenceManyField>)
}
