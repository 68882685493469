import * as React from 'react'
import {
  Pagination,
  TextField,
  ReferenceManyField,
} from 'react-admin'
import EditListButtons from 'src/components/list/EditListButtons'
import RelatedList from 'src/components/list/RelatedList'
import {ModalCreateButton, ModalEditButton} from 'src/components/Modal/ModalButton'
import {useRecordContext} from 'ra-core'
import {CourseModuleCreateModal} from 'src/resources/course/module/CourseModuleCreateModal'
import {CourseModuleEditModal} from 'src/resources/course/module/CourseModuleEditModal'
import TransTextField from 'src/components/fields/TransTextField'
import AssetField from 'src/components/fields/AssetField'
import {CourseBlockList} from 'src/resources/course/block/CourseBlockList'

interface Props {
  record?: any;
}
const Panel = () => {
    return (
        <CourseBlockList/>
    )
}
export const CourseModuleList = (props: Props) => {
   const record = useRecordContext()
  console.log('ListProps', record)
  return (

      <ReferenceManyField {...props} reference="course-module" target="courseId"
                        sort={{field: 'order', order: 'ASC'}} pagination={<Pagination/>} perPage={50}>
        <RelatedList record={record} bulkActionButtons={false} resource={'course-module'}   emptyTitle={'Нет модулей'} expand={<Panel/>}
                     actions={<ModalCreateButton modal={<CourseModuleCreateModal mainRecord={record} refresh/>}/>}
                     createButton={<ModalCreateButton modal={<CourseModuleCreateModal mainRecord={record} refresh/>}/>}>
            <TransTextField source={'title'} label={'Название'} />
            <AssetField source={'image'} label={'Картинка'} />
            <TextField source={'order'} label={'Сортировка'}/>
        <EditListButtons editButton={<ModalEditButton modal={<CourseModuleEditModal mainRecord={record} refresh/>}/>}/>
        </RelatedList>
    </ReferenceManyField>)
}
