import * as React from 'react'
import {FC} from 'react'
import {
  Edit,
  EditProps,
} from 'react-admin'
import {PushForm} from 'src/resources/push/PushForm'
interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
  record ? <span>Push уведомление: {record.name || record.title}</span> : null

const PushEdit: FC<EditProps> = props => {
  return (
    <Edit {...props} mutationMode="pessimistic" title={<Title/>}>
     <PushForm/>
    </Edit>
  )
}
export default PushEdit