import * as React from 'react'
import { FieldProps, RaRecord} from 'react-admin'
import LinkField from 'src/components/fields/LinkField'
interface Props<RecordType extends RaRecord = any> extends FieldProps {
}

const CourseLinkField = (props: Props) => {
   return  <LinkField link={(record) => `/course/${record[props.source ?? 'course']?.id}`} source={props.source ?? 'course.name'} value={(record) => record[props.source ?? 'course']?.name}  label={props.label}/>
}

export default CourseLinkField
