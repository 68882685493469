
export enum PurchaseType {
    character = 'character',
    personalPage = 'personalPage',
    characterItem = 'characterItem',
    vacancyTariff = 'vacancyTariff',
    cvTariff = 'cvTariff',
    contactsOpen = 'contactsOpen',
    addContactsOpen = 'addContactsOpen',
}
export const PurchaseTypeList = [
    {id: PurchaseType.character, name: 'Персонаж'},
    {id: PurchaseType.personalPage, name: 'Конструктор страницы'},
    {id: PurchaseType.characterItem, name: 'Персонаж'},
    {id: PurchaseType.vacancyTariff, name: 'Вакансия'},
    {id: PurchaseType.cvTariff, name: 'Резюме'},
    {id: PurchaseType.contactsOpen, name: 'Открытие профиля соискателя'},
    {id: PurchaseType.addContactsOpen, name: 'Доп открытия профиля соискателя'},
]
