import * as React from 'react'
import {
  TabbedForm,
  ReferenceArrayInput,
  AutocompleteArrayInput,
  FormTab, TextInput, BooleanInput, NumberInput, required, SelectInput, SimpleFormIterator, ArrayInput,
} from 'react-admin'
import {useFormContext} from 'react-hook-form'
import {MediaInput} from 'src/components/inputs/MediaInput'
import {ArticleCategoryType} from 'src/types/interfaces/IArticle'
import {getTranslation} from 'src/utils/translation'
import {CourseModuleList} from 'src/resources/course/module/CourseModuleList'
import {CourseArticleList} from 'src/resources/course/article/CourseArticleList'
import {LangList} from 'src/types'

const InfoTab = (props: any) => {
  const form = useFormContext()
  return (<FormTab
    label="Курс"
    path={'benefit'}
    {...props}>
    <BooleanInput source="published" label={'Опубликовано'} variant={'outlined'}/>

    <ReferenceArrayInput
        filter={{'translations.languageCode': 'ru', 'categoryType:$in': [ArticleCategoryType.Course, ArticleCategoryType.CourseCompilation]}}
        sort={{field: 'translations.name', order: 'ASC'}}
        allowEmpty={false} source="categoriesIds" reference="article-category"
        variant={'outlined'} fullWidth={true} perPage={50}
    >
      <AutocompleteArrayInput fullWidth={true} validate={required()}  label="Категории"
                              filterToQuery={(q: string) => ({'translations.name:$contL': q, 'translations.languageCode': 'ru'})}
                              optionText={(record) => `${record.categoryType === ArticleCategoryType.CourseCompilation ? 'Подборка: ' : ''}${getTranslation(record)?.name}`} />
    </ReferenceArrayInput>


    <ArrayInput source="translations" label={'Контент'}>
      <SimpleFormIterator fullWidth>
        <SelectInput source="languageCode" label={'Язык'} variant={'outlined'} choices={LangList} validate={required()} />

        <TextInput source="name" label={'Название'} variant={'outlined'} fullWidth />
        <TextInput source="shortDescription" label={'Короткое описание'} variant={'outlined'} fullWidth multiline />
        <TextInput source="fullDescription"  label={'Описание'} variant={'outlined'} fullWidth multiline />
        <TextInput source="audience" label={'Аудитория'}  multiline={true} variant={'outlined'} fullWidth   />
        <TextInput source="requirements" multiline={true} label={'Требования'} variant={'outlined'} fullWidth  />
        <TextInput source="benefits" multiline={true} label={'Преимущества'} variant={'outlined'} fullWidth  />
        <TextInput source="priceDescription" multiline={true} label={'Описание цены'} variant={'outlined'} fullWidth  />
        <TextInput source="courseTime" label={'Время обучения'} variant={'outlined'} fullWidth />
      </SimpleFormIterator>
    </ArrayInput>

  <ReferenceArrayInput
        filterToQuery={(searchText: string) => ({...(searchText ? {'name||$contL': searchText} : {})})}
        allowEmpty={false} source="authorIds" reference="course-author"
        format={users => (users || []).map(((user: any) => user && user.name ? user.name : user))}
        variant={'outlined'} fullWidth={true}
        sort={{field: 'name', order: 'ASC'}}
    >
      <AutocompleteArrayInput          label={'Авторы'} source="id" optionText={i => i ? `#${i.id} ${i?.name}` : ''} />
    </ReferenceArrayInput>
    <MediaInput source={'image'} label={'Картинка'} />
    <MediaInput source={'video'} label={'Видео'}   accept={['image/jpeg', 'image/png', 'image/svg+xml', 'video/*']}/>

    <NumberInput source="price" label={'Цена'} variant={'outlined'} fullWidth validate={required()} />
    <TextInput source="currency"  label={'Валюта'} variant={'outlined'} fullWidth />
    <SelectInput source="language" label={'Язык'}  variant={'outlined'} choices={LangList} validate={required()} />
    <MediaInput source={'certificate'} label={'Сертификат'} accept={['image/jpeg', 'image/png', 'image/svg+xml', 'video/*', 'application/pdf']} />

  </FormTab>)
}

const ArticlesTab = (props: any) => {
  return (<FormTab {...props} label={'Новости'}>
    <CourseArticleList {...props}/>
  </FormTab>)
}
const ModulesTab = (props: any) => {
  return (<FormTab {...props} label={'Модули'}>
    <CourseModuleList {...props}/>
  </FormTab>)
}
const CourseForm = (props: any) => {
  return (
    <TabbedForm {...props} >
      <InfoTab/>
      {props.isEdit && <ModulesTab/>}
      {props.isEdit && <ArticlesTab/>}
    </TabbedForm>
  )
}
export default CourseForm
