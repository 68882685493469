import * as React from 'react'
import { FieldProps, RaRecord} from 'react-admin'
import LinkField from 'src/components/fields/LinkField'
interface Props<RecordType extends RaRecord = any> extends FieldProps {
}

const VacancyLinkField = (props: Props) => {

   return  <LinkField link={(record) => `/vacancy/${record[props.source ?? 'vacancy']?.id}/show`} source={props.source ?? 'vacancy.name'} value={(record) => record[props.source ?? 'vacancy']?.name}  label={props.label}/>

}

export default VacancyLinkField
