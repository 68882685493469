import * as React from 'react'
import {FC} from 'react'
import {
  EditProps,
} from 'react-admin'
import ArticleCategoryForm from 'src/resources/article-category/ArticleCategoryForm'
import {CustomEdit} from 'src/components/CustomEdit'

interface TitleProps {
  record?: any;
}
const Title: FC<TitleProps> = ({record}) =>
  record ? <span>Категория {record.name}</span> : null

const ArticleCategoryEdit: FC<EditProps> = props => {
  return (
    <CustomEdit {...props} redirect={'list'}  mutationMode="pessimistic" title={<Title/>}>
    <ArticleCategoryForm/>
    </CustomEdit>
  )
}
export default ArticleCategoryEdit