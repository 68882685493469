import ListIcon from '@mui/icons-material/List'
import CharacterList from 'src/resources/character/CharacterList'
import CharacterEdit from 'src/resources/character/CharacterEdit'
import CharacterCreate from 'src/resources/character/CharacterCreate'

export default {
  create: CharacterCreate,
  edit: CharacterEdit,
  list: CharacterList,
  icon: ListIcon,
}