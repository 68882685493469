import * as React from 'react'
import {
    Pagination,
    TextField,
    SelectField,
    ReferenceManyField,
} from 'react-admin'
import EditListButtons from 'src/components/list/EditListButtons'
import RelatedList from 'src/components/list/RelatedList'
import {ModalCreateButton, ModalEditButton} from 'src/components/Modal/ModalButton'
import {useRecordContext} from 'ra-core'
import {CourseBlockStepCreateModal} from 'src/resources/course/step/CourseBlockStepCreateModal'
import {CourseBlockStepEditModal} from 'src/resources/course/step/CourseBlockStepEditModal'
import {CourseAnswerTypeList, CourseStepTypeList} from 'src/types/interfaces/ICourse'
import TransTextField from 'src/components/fields/TransTextField'
import {getTranslation} from 'src/utils/translation'

interface Props {
  record?: any;
}

export const CourseBlockStepList = (props: Props) => {
   const record = useRecordContext()
  console.log('ListProps', record)
  return (

      <ReferenceManyField {...props} reference="course-block-step" target="courseBlockId"
                        sort={{field: 'order', order: 'ASC'}} pagination={<Pagination/>} perPage={50}>
        <RelatedList record={record} resource={'course-block-step'}   emptyTitle={'Нет шагов'}
                     title={`Шаги блока «${getTranslation(record as any)?.title}»`}
                     actions={[<ModalCreateButton key={1} modal={<CourseBlockStepCreateModal mainRecord={record} refresh/>}/>]}
                     createButton={<ModalCreateButton modal={<CourseBlockStepCreateModal mainRecord={record} refresh/>}/>}>
            <TransTextField source={'title'} label={'Название'}/>

            <SelectField source={'stepType'} label={'Тип шага'} choices={CourseStepTypeList}  />
            <SelectField source={'answerType'} label={'Тип ответа'} choices={CourseAnswerTypeList}  />
            <TextField source={'order'} label={'Сортировка'}/>

            <EditListButtons editButton={<ModalEditButton modal={<CourseBlockStepEditModal mainRecord={record} refresh/>}/>}/>
        </RelatedList>
    </ReferenceManyField>)
}
