
export const PushTypes = [
    { id: 'admin', name: 'Общее уведомление' },
    { id: 'userRegistered', name: 'Регистрация' },
    { id: 'chatMessage', name: 'Сообщение' },
    { id: 'newApplication', name: 'Новый отклик' },
    { id: 'newProposal', name: 'Новый оффер' },
]
export const PushNotificationChannel = [
    { id: 'push', name: 'Push' },
    { id: 'alert', name: 'Уведомление' }
]
export const PushTargetTypes = [
    { id: 'all', name: 'Всем' },
    { id: 'user', name: 'Пользователю' }
]
export const PushStatuses = [
    { id: 'created', name: 'Черновик' },
    { id: 'toSent', name: 'Отправить' },
]
export const PushPriorities = [
    { id: 'high', name: 'Высокий' },
    { id: 'normal', name: 'Обычный' },
]
export const PushSoundTypes = [
    { id: '', name: 'Отключен' },
    { id: 'default', name: 'Включен' },
]