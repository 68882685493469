import React from 'react'
import {Admin} from '@glm/ra-enterprise'
import {Resource} from 'src/components/Resource'
import {AuthProvider} from 'src/common/providers/AuthProvider'
import {dataProvider} from 'src/common/providers/dataProvider'
import russianMessages from 'ra-language-russian'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import {darkTheme, lightTheme} from 'src/style'
//PlopImport

import Tariff from 'src/resources/tariff'

import Vertical from 'src/resources/vertical'




import StoryBlock from 'src/resources/story-block'

import Feedback from 'src/resources/feedback'
import Community from 'src/resources/community'
import Source from 'src/resources/source'
import Stopword from 'src/resources/stopword'
import Story from 'src/resources/story'
import CourseAuthor from 'src/resources/course-author'
import Course from 'src/resources/course'
import article from 'src/resources/article'
import articleCategory from 'src/resources/article-category'
import cv from 'src/resources/cv'
import vacancy from 'src/resources/vacancy'
import characterItem from 'src/resources/character-item'
import characterItemCategory from 'src/resources/character-item-category'
import admin from 'src/resources/admin'
import user from 'src/resources/user'
import profile from 'src/resources/profile'
import application from 'src/resources/application'
import chat from 'src/resources/chat'
import character from 'src/resources/character'
import personalPage from 'src/resources/personal-page'
import proposal from 'src/resources/proposal'
import push from 'src/resources/push'
import pushAdmin from 'src/resources/push/admin'
import page from 'src/resources/page'
import media from 'src/resources/media'
import skill from 'src/resources/skill'
import serviceCategory from 'src/resources/service-category'
import {AppLayout} from 'src/components/layout'
import background from 'src/resources/background'
import backgroundCategory from 'src/resources/background-category'

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru')

const App = () => {
    console.log('darkTheme', darkTheme)
    return (
        <Admin
            lightTheme={lightTheme}
            darkTheme={darkTheme}
            i18nProvider={i18nProvider}
            dataProvider={dataProvider}
            authProvider={AuthProvider}
            layout={AppLayout}
            requireAuth
        >
            <Resource key={'story'} name={'story'} {...Story} options={{label: 'Блоки историй'}}/>

            <Resource key={'story-block'} name={'story-block'} {...StoryBlock} options={{label: 'Истории'}}/>
            <Resource key={'article-news'} name="article-news" {...article} options={{label: 'Статьи'}}/>
            <Resource key={'article-category-news'} name="article-category-news" {...articleCategory}
                      options={{label: 'Статьи Категории'}}/>
            <Resource key={'article-knowledge'} name="article-knowledge" {...article} options={{label: 'База знаний'}}/>
            <Resource key={'article-category-knowledge'} name="article-category-knowledge" {...articleCategory}
                      options={{label: 'База знаний категории'}}/>
            <Resource key={'course-compilation-category'} name="course-compilation-category" {...articleCategory}
                      options={{label: 'Подборки курсов категории'}}/>
            <Resource key={'course-category'} name="course-category" {...articleCategory}
                      options={{label: 'Курсы категории'}}/>
            <Resource key={'course-module'} name={'course-module'} options={{label: 'Модули курсов'}}/>
            <Resource key={'course'} name={'course'} {...Course} options={{label: 'Курсы'}}/>
            <Resource key={'course-news'} name={'course-news'} options={{label: 'Новости курсов'}}/>
            <Resource key={'course-author'} name={'course-author'} {...CourseAuthor}
                      options={{label: 'Авторы курсов'}}/>
            <Resource key={'course-block'} name={'course-block'} />
            <Resource key={'cv'} name="cv" {...cv} options={{label: 'Резюме'}}/>
            <Resource key={'cv-approved'} name="cv-approved" {...cv} options={{label: 'Резюме'}}/>
            <Resource key={'cv-moderation'} name="cv-moderation" {...cv} options={{label: 'Резюме'}}/>
            <Resource key={'vacancy'} name="vacancy" {...vacancy} options={{label: 'Вакансии'}}/>
            <Resource key={'vacancy-approved'} name="vacancy-approved" {...vacancy} options={{label: 'Вакансии'}}/>
            <Resource key={'vacancy-moderation'} name="vacancy-moderation" {...vacancy} options={{label: 'Вакансии'}}/>
            <Resource key={'application'} name="application" {...application} options={{label: 'Отклики'}}/>
            <Resource key={'proposal'} name="proposal" {...proposal} options={{label: 'Приглашения'}}/>
            <Resource key={'chat'} name="chat" {...chat} options={{label: 'Чаты'}}/>
            <Resource key={'chat-message'} name="chat-message"/>
            <Resource key={'character'} name="character" {...character} options={{label: 'Персонажи'}}/>
            <Resource key={'character'} name="character" {...personalPage} options={{label: 'Перс. страницы'}}/>
            <Resource key={'character-item'} name="character-item" {...characterItem} options={{label: 'Вещи'}}/>
            <Resource key={'character-item-category'} name="character-item-category" {...characterItemCategory}
                      options={{label: 'Категории вещей'}}/>
            <Resource key={'user'} name="user" {...user} options={{label: 'Пользователи'}}/>
            <Resource key={'profile'} name="profile" {...profile} options={{label: 'Профили'}}/>
            <Resource key={'profile-hirer'} name="profile-hirer" {...profile} options={{label: 'Профили'}}/>
            <Resource key={'profile-employee'} name="profile-employee" {...profile} options={{label: 'Профили'}}/>
            <Resource key={'page'} name="page" {...page} options={{label: 'Текстовые страницы'}}/>
            <Resource key={'skill'} name="skill" {...skill} options={{label: 'Навыки'}}/>
            <Resource key={'service-category'} name="service-category" {...serviceCategory}
                      options={{label: 'Категории'}}/>
            <Resource key={'service-category'} name="background" {...background}
                      options={{label: 'Фоны'}}/>
            <Resource key={'service-category'} name="background-category" {...backgroundCategory}
                      options={{label: 'Категории фонов'}}/>
            <Resource key={'asset'} name="asset" {...media} options={{label: 'Файлы'}}/>
            <Resource key={'notification'} name="notification" {...push} options={{label: 'Уведомления'}}/>
            <Resource key={'notification-admin'} name="notification-admin" {...pushAdmin}
                      options={{label: 'Рассылки'}}/>
            <Resource key={'admin'} name="admin" {...admin} options={{label: 'Администраторы'}}/>
            <Resource key={'location'} name="location"/>
            {/*PlopResource*/}
            <Resource key={'feedback'} name={'feedback'} {...Feedback} options={{label: 'Отзывы'}}/>
            <Resource key={'community'} name={'community'} {...Community} options={{label: 'Сообщества'}}/>
            <Resource key={'source'} name={'source'} {...Source} options={{label: 'Источники'}}/>
            <Resource key={'stopword'} name={'stopword'} {...Stopword} options={{label: 'Стоп слова'}}/>
            <Resource key={'vertical'} name={'vertical'} {...Vertical} options={{label: 'Вертикали'}}/>
            <Resource key={'tariff'} name={'tariff'} {...Tariff} options={{label: 'Тарифы'}}/>
        </Admin>
    )
}

export default App
