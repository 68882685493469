import * as React from 'react'
import {FC} from 'react'
import {
  Show,
  SimpleShowLayout,
  DateField,
  EditProps,
  TextField,
} from 'react-admin'

import ProfileLinkField from 'src/components/fields/ProfileLinkField'
import CourseLinkField from 'src/components/fields/CourseLinkField'

interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Отзыв #{record?.id} </span>

const FeedbackShow: FC<EditProps> = props => {
  return (<Show {...props} title={<Title/>}>
    <SimpleShowLayout>
       <TextField source={'id'} label={'ID'}/>
        <ProfileLinkField source={'profileId'} label={'Профиль'}/>
        <CourseLinkField source={'courseId'} label={'Курс'}/>
        <TextField source={'mark'} label={'Оценка'}/>
        <TextField source={'content'} label={'Отзыв'}/>
        <DateField source={'createdAt'} label={'Создан'} showTime={true} />
    </SimpleShowLayout>
  </Show>)
}

export default FeedbackShow