import * as React from 'react'
import {FC} from 'react'
import {
  EditProps,
} from 'react-admin'
import CourseAuthorForm from 'src/resources/course-author/CourseAuthorForm'
import {CustomEdit} from 'src/components/CustomEdit'
import {useRecordContext} from 'ra-core'

interface TitleProps {
  record?: any;
}
const Title: FC<TitleProps> = () => {
  const record = useRecordContext()
   return record ? <span>Автор курса {record.name}</span> : null
}
const CourseAuthorEdit: FC<EditProps> = props => {
  return (
    <CustomEdit {...props} redirect="list" mutationMode="pessimistic" title={<Title/>}>
      <CourseAuthorForm/>
    </CustomEdit>
  )
}
export default CourseAuthorEdit
