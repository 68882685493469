import ListIcon from '@mui/icons-material/List'
import FeedbackList from 'src/resources/feedback/FeedbackList'
import FeedbackEdit from 'src/resources/feedback/FeedbackEdit'
import FeedbackCreate from 'src/resources/feedback/FeedbackCreate'
import FeedbackShow from 'src/resources/feedback/FeedbackShow'

export default {
  create: FeedbackCreate,
  edit: FeedbackEdit,
  list: FeedbackList,
  show: FeedbackShow,
  icon: ListIcon,
}