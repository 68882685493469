import * as React from 'react'
import {FC, useRef} from 'react'
import {
    ArrayInput, BooleanInput, FormDataConsumer,
    required, SelectInput, SimpleFormIterator,
    TextInput,
} from 'react-admin'
import Box from '@mui/material/Box'
import {IFocalPoint} from 'src/components/types'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import {MediaInput} from 'src/components/inputs/MediaInput'
import {CourseAnswerTypeList, CourseStepType, CourseStepTypeList} from 'src/types/interfaces/ICourse'
import {LangList} from 'src/types'
type UpdateCallback = (value: IFocalPoint | null) => void
interface ProductTitleProps {
    record?: any;
}
export const CourseBlockStepEditForm: FC<any> = props => {
    const {record} = props
    const currentEditRef = useRef()
    return (
        <Box >

            <SelectInput source={'stepType'} label={'Тип шага'} choices={CourseStepTypeList} variant={'outlined'} fullWidth validate={required()} />
            <MediaInput source={'image'} label={'Картинка'} />

            <ArrayInput source="translations" label={'Контент'}>
                <SimpleFormIterator fullWidth>
                    <SelectInput source="languageCode" label={'Язык'} variant={'outlined'} choices={LangList} validate={required()} />
                    <TextInput source="title" label={'Заголовок'} variant={'outlined'} fullWidth validate={required()} />
                    <RichTextInput source={'body'} label={'Текст'} variant={'outlined'} validate={required()} fullWidth={true}/>
                </SimpleFormIterator>
            </ArrayInput>

            <FormDataConsumer fullWidth={true} variant={'outlined'}>
                {({ formData, ...rest }) => formData.stepType === CourseStepType.Question  && (

                    <SelectInput source={'answerType'} label={'Тип ответа'} choices={CourseAnswerTypeList} variant={'outlined'} fullWidth validate={required()} />

                )}
            </FormDataConsumer>


            <FormDataConsumer fullWidth={true} variant={'outlined'}>
                {({ formData, ...rest }) => formData.stepType === CourseStepType.Question  && (

                    <ArrayInput source="variants" label={'Варианты ответа'}>
                        <SimpleFormIterator fullWidth>
                            <TextInput source="id" label={'ID'} helperText={'ID ответа (порядковая цифра)'} variant={'outlined'}  validate={required()} />
                            <BooleanInput source={'correct'} label={'Ответ правильный'} variant={'outlined'}  fullWidth={true}/>
                            <ArrayInput source="translations" label={'Контент'}>
                                <SimpleFormIterator fullWidth>
                                    <SelectInput source="languageCode" label={'Язык'} variant={'outlined'} choices={LangList} validate={required()} />
                                    <TextInput source="answer" label={'Заголовок'} variant={'outlined'} fullWidth validate={required()} />
                                </SimpleFormIterator>
                            </ArrayInput>

                        </SimpleFormIterator>
                    </ArrayInput>
                )}
            </FormDataConsumer>
            <TextInput
                source="order"
                label={'Сортировка'}
                fullWidth
                helperText={false}
                variant={'outlined'}
            />

        </Box>

    )
}
const requiredValidate = [required()]


