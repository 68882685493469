import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,
    Datagrid,
    ReferenceField,
    SearchInput,
    TextField,
    SelectField, DateField, ReferenceInput, SelectInput, required, useResourceContext, FunctionField, BooleanInput
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import {ArticleCategoryType, ArticleType, ArticleTypeList} from 'src/types/interfaces/IArticle'
import TransTextField from 'src/components/fields/TransTextField'
import {getTranslation} from 'src/utils/translation'
import CommunityInput from 'src/components/inputs/CommunityInput'
import ProfileInput from 'src/components/inputs/ProfileInput'


const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'published': 'Опубликовано',
            'name': 'Название',
            'type': 'Тип',
            'entityListType': 'Список',
            'category': 'Категория',
            'seoTitle': 'SEO Title',
            'seoDescription': 'SEO Description',
            'seoKeywords': 'SEO Keywords',

            'userEmail': 'Пользователь(Email)',
            'tags': 'Теги',
            'totalViews': 'Просмотры',
            'totalLikes': 'Лайки',
            'isTop': 'Топ',
            'publishedAt': 'Публикация',
            'adminUpdatedAt': 'Обновлено',
            'admin': 'Админ'

        },
        data: posts.map((item: any) => ({
            ...item,
            admin: item.admin?.name || '',
            category: item.category?.name || '',
            user: item.user?.nickname || '',
            userEmail: item.user?.email || '',
            type: ArticleTypeList.find(i => i.id === item.type)?.name || '',
            published: item.published ? 'Да' : 'Нет',
            isTop: item.isTop ? 'Топ' : '',
            createdAt: formatCsvDate(item.createdAt),
            adminUpdatedAt: formatCsvDate(item.adminUpdatedAt),
            publishedAt: formatCsvDate(item.publishedAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'Articles'
    })
}

const _Filter = (props: any) => {
    const resource = useResourceContext()
    return (
        <Filter {...props}>
            <ReferenceInput source="articleCategoryId" reference="article-category" variant={'outlined'}
                            filter={{categoryType: resource === 'article-news' ? ArticleCategoryType.News : ArticleCategoryType.Knowledge}}
                            sort={{field: 'translations.name', order: 'ASC'}} perPage={100}>
                <SelectInput fullWidth={true} validate={required()} label="Категория"
                             optionText={(record) => `${getTranslation(record)?.name}`}/>
            </ReferenceInput>
            <SearchInput source="name" variant={'outlined'} alwaysOn/>
            <BooleanInput source={'isTop'} label={'Топ'}  variant={'outlined'}  fullWidth={true}/>
            <CommunityInput source={'communityId'} label={'Сообщество'}/>
            <ProfileInput source={'profileId'} label={'Профиль'}/>
        </Filter>
    )
}

const ArticleList: FC<ListProps> = props => {
    const resource = useResourceContext()
    const isNews = resource === 'article-news'
    const getDefaultType = () => {
        switch (resource){
            case 'article-category-news':
                return 'Новости'
            case 'article-category-knowledge':
                return 'База знаний'
            case 'course-category':
                return 'Курс'
            case 'course-news':
                return ArticleType.CourseNews
        }
    }
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title={isNews ? 'Новости' : 'База знаний'}
            sort={{field: 'publishedAt', order: 'DESC'}}
            empty={<EmptyList title={isNews ? 'Нет новостей' : 'нет статей'} description={'Вы можете добавить статью'}
                              buttonText={isNews ? 'Добавить новость' : 'Добавить статью'}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField source={'id'} label={'ID'}/>
                <VisibilityField source="published" label={'П'}/>
                <ReferenceField label="Категория" source="articleCategoryId" reference="article-category">
                    <TransTextField source="name"/>
                </ReferenceField>
                <TextField source="name" label={'Название'}/>
                <SelectField source="articleType" label={'Тип'} choices={ArticleTypeList}/>
                <FunctionField source={'isTop'} label={'Топ'} render={(i: any) => i.isTop ? 'Да' : ''} />
                <DateField source="publishedAt" label={'Время публикации'} showTime={true}/>
                <TextField source="totalLikes" label={'Лайков'}/>
                <TextField source="totalViews" label={'Посмотров'}/>
                <TextField source="order" label={'Сортировка'}/>
                <TextField source="orderCategory" label={'Сортировка в кат.'}/>
               <ReferenceField label="Админ" source="adminId" reference="admin">
                    <TextField source="name"/>
                </ReferenceField>
                <DateField source="updatedAt" label={'Обновлено'} showTime={true}/>
            </Datagrid>
        </List>
    )
}

export default ArticleList
