import * as React from 'react'
import {FC} from 'react'
import {
  Create,
  EditProps,
} from 'react-admin'
import FeedbackForm from 'src/resources/feedback/FeedbackForm'

interface TitleProps {
  record?: any;
}

const Title = (props: TitleProps) =>
 <span>Новая Отзыв</span>

const FeedbackCreate: FC<EditProps> = props => {
  return (
    <Create {...props} redirect="list"  title={<Title/>}>
      <FeedbackForm/>
    </Create>
  )
}

export default FeedbackCreate