import * as React from 'react'
import {FC} from 'react'
import {
  Filter,
  List,
  ReferenceInput,
  AutocompleteInput,
  TextInput,
  SelectInput,
  SelectField,
  FunctionField,
  ReferenceField,
  ListProps,
  Datagrid, DateField, usePermissions
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import {
  PushNotificationChannel,
  PushPriorities,
  PushSoundTypes,
  PushTargetTypes,
  PushTypes
} from 'src/types/interfaces/IPush'
import PushNameField from 'src/resources/push/components/PushNameField'
import ChannelsField from 'src/resources/push/components/ChannelsField'

const exporter = (posts: any) => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'type': 'Тип',
      'targetType': 'Кому отправить',
      'title': 'Заголовок',
      'body': 'Тело',
      'channels': 'Каналы',
      'statusName': 'Статус',
      'typeName': 'Кому',
      'sound': 'Звук',
      'priority': 'Приоритет',
      'scheduleSentAt': 'Отправить в',
      'createdAt': 'Создано',
      'admin': 'Админ'
    },
    data: posts.map((item: any) => ({
      ...item,
      title: getTranslation(item)?.title,
      body: getTranslation(item)?.body,
      channels: item.channels.filter((item: any) => PushNotificationChannel.find(i => i.id === item)).map((item: any) => PushNotificationChannel.find(i => i.id === item)?.name).join(', '),
      admin: item.admin?.name || '',
      type: PushTypes.find(i => i.id === item.type)?.name || '',
      targetType: PushTargetTypes.find(i => i.id === item.targetType)?.name || '',
      sound: PushSoundTypes.find(i => i.id === item.sound)?.name || '',
      priority: PushPriorities.find(i => i.id === item.priority)?.name || '',
      createdAt: formatCsvDate(item.createdAt),
      scheduleSentAt: formatCsvDate(item.createdAt),
      updatedAt: formatCsvDate(item.updatedAt)
    })),
    fileName: 'push'
  })
}

const _Filter = (props: any) => (
  <Filter {...props}>

    <TextInput source="translations.title:$contL" label={'Поиск'} alwaysOn={true}
               resettable={true} variant={'outlined'}/>

    <SelectInput
      source="type"
      label={'Тип'}
      variant={'outlined'} fullWidth={true}
      choices={PushTypes}
    />
    <SelectInput
      source="targetType"
      label={'Кому'}
      variant={'outlined'} fullWidth={true} alwaysOn={true}
      choices={PushTargetTypes}
    />
    <ReferenceInput
        allowEmpty={false}
       source="userId" reference="user" variant={'outlined'}
        filterToQuery={(searchText: string) => ({...(searchText ? {'id||$eq': searchText} : {})})}
        sort={{field: 'id', order: 'ASC'}} perPage={100}>
        <AutocompleteInput optionText={(i) => `#${i.id} ${i.defaultProfile?.name || ''}`}  label="Пользователь" />
    </ReferenceInput>
  </Filter>
)

const PushList: FC<ListProps> = props => {
  const { permissions } = usePermissions()
  return (
    <List
      {...props}
      exporter={exporter}
      filters={<_Filter/>}
      sort={{field: 'id', order: 'DESC'}}
      title="Push уведомления"
      empty={<EmptyList title={'Нет push уведомления'} description={'Вы можете добавить push уведомление'}
                        buttonText={'Добавить push уведомление'}/>}
    >
      <Datagrid rowClick={'edit'} bulkActionButtons={false}>
        <IdShowButtonField source="id" label={'ID'}/>
        <PushNameField source="name" label={'Push'}/>
        <SelectField source="type" choices={PushTypes} label={'Тип'}/>
        <SelectField source="targetType" choices={PushTargetTypes} label={'Кому'}/>
        <ChannelsField source={'channels'} label={'Каналы'}/>
        <DateField source={'scheduleSentAt'} label={'Отправить в'} showTime={true}/>
        <DateField source={'createdAt'} label={'Создано'} showTime={true}/>
         <ReferenceField label="Пользователь" source="userId" reference="user">
            <FunctionField render={(i: any) => `#${i.id} ${i.defaultProfile?.name || ''}`}/>
        </ReferenceField>


      </Datagrid>
    </List>
  )
}

export default PushList
