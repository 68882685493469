import {AutocompleteInput, ReferenceInput} from 'react-admin'
import * as React from 'react'


interface Props{
    source?: string
    label?: string
    disabled?: boolean;
    allowEmpty?: boolean
    resettable?: boolean
    alwaysOn?: boolean
    fullWidth?: boolean
    validate?: any
}
const StoryInput = (props: Props) => {
return ( <ReferenceInput
    allowEmpty={props.allowEmpty}
    source={props.source ?? 'storyId'} reference="story" variant={'outlined'}
    sort={{field: 'id', order: 'DESC'}} perPage={100} {...props as any}>
    <AutocompleteInput noOptionsText="-"  filterToQuery={(q: string) => ({'name:$contL': q})} optionText={(i) => i.name} label={props.label ?? 'Блок'}/>
</ReferenceInput>)
}

export default StoryInput