import * as React from 'react'
import {FC} from 'react'
import {
  Create,
  EditProps,
} from 'react-admin'
import BackgroundCategoryForm from 'src/resources/background-category/BackgroundCategoryForm'

interface TitleProps {
  record?: any;
}

const Title = (props: TitleProps) =>
 <span>Новая Категория фонов</span>

const BackgroundCategoryCreate: FC<EditProps> = props => {
  return (
    <Create {...props} redirect="list"  title={<Title/>}>
      <BackgroundCategoryForm isNew/>
    </Create>
  )
}

export default BackgroundCategoryCreate
