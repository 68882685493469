import * as React from 'react'
import {useEffect, useRef, useState} from 'react'
import classnames from 'classnames'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import {
    useDataProvider
} from 'react-admin'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Dialog from '@mui/material/Dialog'
import Pagination from '@mui/material/Pagination'
import MuiGridList from '@mui/material/ImageList'
import {Button} from '@mui/material'
import {useDropzone} from 'react-dropzone'
import Cookies from 'js-cookie'
import axios, {CancelTokenSource} from 'axios'
import styles from './index.module.scss'
import {IAsset} from 'src/components/types'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
    Breakpoint,
    Theme,
    useTheme,
} from '@mui/material/styles'
import AssetListItem from 'src/components/AssetListItem'

interface ModalMediaProps {
    isShown?: boolean
    onClose?: () => void
    title?: string
    fullScreen?: boolean
    fullWidth?: boolean
    filter?: any
}

interface ModalMediaViewProps {
    onSelect?: (record: IAsset) => void
    onClose?: () => void,
    maxSize?: number,
    accept?: string[],
    filter?: any
}

type BreakpointOrNull = Breakpoint | null;

function useWidth() {
    const theme: Theme = useTheme()
    const keys: readonly Breakpoint[] = [...theme.breakpoints.keys].reverse()
    return (
        keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            const matches = useMediaQuery(theme.breakpoints.up(key))
            return !output && matches ? key : output
        }, null) || 'xs'
    )
}

const ModalMedia = (props: ModalMediaProps & ModalMediaViewProps) => {


    return <Dialog
        fullScreen={props.fullScreen}
        fullWidth={props.fullWidth}
        open={props.isShown ?? false}
        onClose={props.onClose}
        aria-label={props.title}
    >
        {props.isShown && <ModalMediaView {...props}/>}
    </Dialog>
}
ModalMedia.defaultTypes = {
    fullWidth: true,
    accept: ['image/jpeg', 'image/png', 'image/svg+xml'],


}


const ModalMediaView = (props: ModalMediaViewProps) => {
    const {onSelect, maxSize, accept} = props
    const [isUploading, setIsUploading] = useState(false)
    const [progress, setProgress] = useState(0)
    const [data, setData] = useState<IAsset[]>([])
    const [page, setPage] = useState(1)
    const [total, setTotal] = useState(0)
    const [currentRecord, setCurrentRecord] = useState<IAsset | null>(null)
    const dataProvider = useDataProvider()
    const abortController = useRef<CancelTokenSource | null>(null)
    const width = useWidth()
    const loadPage = async (page: number) => {
        setPage(page)
        const res = await dataProvider.getList('asset', {
            pagination: {page, perPage: 30},
            sort: {field: 'id', order: 'DESC'},
            filter: props.filter ?? {}
        })
        console.log('LoadPage', res)
        setData(res.data as IAsset[])
        setTotal(res.total as number)
    }
    useEffect(() => {
        loadPage(1)
    }, [])
    const handleChangePage = async (_e: React.ChangeEvent<unknown>, page: number) => {
        loadPage(page)
    }
    const handleSelectAsset = (record: IAsset) => {

        setCurrentRecord(currentRecord && currentRecord.id === record.id ? null : record)
    }
    const handleChoose = () => {
        if (onSelect) {
            onSelect(currentRecord!)
        }
    }
    const handleUploadCancel = () =>
        abortController.current && abortController.current!.cancel()

    const uploadFiles = async (files: File[]) => {
        const cancelToken = axios.CancelToken
        const source = cancelToken.source()
        const token = Cookies.get('auth-token')

        abortController.current = source
        for (const file of files) {
            const form = new FormData()
            form.append('file', file)
            const axiosOptions = {
                headers: {
                    // Content-Type may need to be completely **omitted**
                    // or you may need something
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                cancelToken: source.token,
                onUploadProgress: (e: ProgressEvent) => {
                    // Progress in processing uploads
                    setProgress(e.loaded / e.total)
                },
            }
            const uploadRes = await axios.put(`${process.env.REACT_APP_API_URL || ''}/admin/api/asset/upload`, form, axiosOptions)
            console.log('uploadRes', uploadRes)

        }

    }
    const onDrop = async (newFiles: File[]) => {
        setIsUploading(true)
        await uploadFiles(newFiles)
        setIsUploading(false)
        console.log('OnDrop', newFiles)
        await loadPage(1)
    }
    const getColsForWidth = (width: string) => {
        if (width === 'xs') return 2
        if (width === 'sm') return 3
        if (width === 'md') return 3
        if (width === 'lg') return 5
        return 6
    }

    const {getRootProps, getInputProps, isDragActive} = useDropzone({
        accept,
        maxSize,
        multiple: true,
        onDrop,
    })
    return (<div className={classnames(styles.root, {[styles.dragActive]: isDragActive})}>
            <div className={styles.dialogTitle}><Typography variant="h6">Файлы</Typography>
                <div className={styles.dialogTitleActions} {...getRootProps()}>
                    <Button variant="contained" color="primary">
                        Загрузить файл <input {...getInputProps()} /></Button>
                </div>
            </div>
            <DialogContent>
                <MuiGridList
                    rowHeight={180}
                    cols={getColsForWidth(width)}
                    className={styles.gridList}
                >

                    {data.map((item) => (

                        <AssetListItem key={item.id} item={item} onSelect={handleSelectAsset} isActive={currentRecord?.id === item.id}/>
                    ))}

                </MuiGridList>
            </DialogContent>
            <DialogActions>

                <Pagination page={page} size={'small'} count={Math.ceil(total / 30)} onChange={handleChangePage}/>
                <Button disabled={!currentRecord} variant="contained" color="primary" onClick={handleChoose}>
                    Выбрать
                </Button>
            </DialogActions>
            {isDragActive && <div className={styles.dropZone}>
                <div className={styles.dropZoneWrapper}>
                    <div className={styles.dropZoneText}>Перетащите файлы сюда</div>
                </div>
            </div>}

            {isUploading && <div className={styles.uploading}>
                <CircularProgressWithLabel value={progress}/>
                <Button variant="contained" sx={(theme) => ({
                    marginTop: '20px',
                    background: theme.palette.error.main
                })} color="primary" onClick={handleUploadCancel}>
                    Отменить
                </Button>
            </div>}
        </div>
    )
}


ModalMediaView.defaultProps = {}

const CircularProgressWithLabel = (props: {value: number}) => {
    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" {...props} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="inherit">{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    )
}


export default ModalMedia
