import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,SearchInput,
    Datagrid, TextInput, TextField,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import AssetField from 'src/components/fields/AssetField'
import TransTextField from 'src/components/fields/TransTextField'

const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'CharacterItemCategory'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>
            <SearchInput source="translations.name:$contL"  variant={'outlined'} fullWidth alwaysOn />
            <TextInput source="key:$contL" label={'Ключ'} variant={'outlined'} fullWidth />
        </Filter>
    )
}

const CharacterItemCategoryList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title="Категории вещей"
            sort={{field: 'sort', order: 'ASC'}}
            empty={<EmptyList title={'Нет'} description={'Вы можете добавить'}
                              buttonText={'Добавить'}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField source={'id'} label={'ID'}/>
                <AssetField source={'image'} label={'Картинка'}/>
                <TransTextField source={'name'} label={'Название'}/>
                <TextField source={'key'} label={'Ключ'}/>
                <TextField source={'sort'} label={'Сортировка'}/>
            </Datagrid>
        </List>
    )
}

export default CharacterItemCategoryList
