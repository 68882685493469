import * as React from 'react'
import {FC, useRef} from 'react'
import {
    ArrayInput,
    required, SelectInput, SimpleFormIterator,
    TextInput,
} from 'react-admin'
import Box from '@mui/material/Box'
import {IFocalPoint} from 'src/components/types'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import {LangList} from 'src/types'

type UpdateCallback = (value: IFocalPoint | null) => void

interface ProductTitleProps {
    record?: any;
}

export const CourseBlockEditForm: FC<any> = props => {
    const {record} = props
    const currentEditRef = useRef()
    return (
        <Box>

            <ArrayInput source="translations" label={'Контент'}>
                <SimpleFormIterator fullWidth>
                    <SelectInput source="languageCode" label={'Язык'} variant={'outlined'}
                                 choices={LangList} validate={required()}/>
                    <TextInput source="title" label={'Название'} variant={'outlined'} fullWidth validate={required()} />

                    <RichTextInput source={'body'} label={'Текст'} variant={'outlined'} validate={required()}
                                   fullWidth={true}/>
                </SimpleFormIterator>
            </ArrayInput>

            <TextInput
                source="blockTime"
                label={'Время прохождения'}
                fullWidth
                helperText={false}
                variant={'outlined'}
            />
            <TextInput
                source="order"
                label={'Сортировка'}
                fullWidth
                helperText={false}
                variant={'outlined'}
            />

        </Box>

    )
}
const requiredValidate = [required()]


