import React, { ComponentProps, ReactElement} from 'react'
import { useInput, FieldTitle } from 'ra-core'
import { InputHelperText } from 'ra-ui-materialui'
import {
  FormHelperText,
  FormControl,
  InputLabel,
} from '@mui/material'
import PropTypes from 'prop-types'
import DateTimePicker from 'react-datetime-picker'
import {formatISO} from 'date-fns'
import styles from './index.module.scss'
export interface DateTimeInputProps {
  label?: string | ReactElement;
  source: string;
    fullWidth?: boolean;

  helperText?: ComponentProps<typeof InputHelperText>['helperText'];
  record?: Record<any, any>;
  resource?: string;
  variant?: string;
  [key: string]: any;
}

const DateTimeInput = (props: DateTimeInputProps) => {
  const {
    options = {}, // Quill editor options
    toolbar = true,
    fullWidth = true,
    classes: classesOverride,
    configureQuill,
    helperText,
    label,
    source,
    resource,
    variant,
    margin = 'dense',
    ...rest
  } = props


  const {
    id,
    isRequired,
    field: { value, onChange },
    fieldState: { isTouched, error },
  } = useInput({ source, ...rest })



  return (<div>
    <FormControl
      error={!!(isTouched && error)}

      className="ra-rich-text-input"
      margin={margin}
    >
      <InputLabel shrink htmlFor={id}>
        <FieldTitle
          label={label}
          source={source}
          resource={resource}
          isRequired={isRequired}
        />
      </InputLabel>

      <DateTimePicker
        {...props}
        value={value ? new Date(value) : value}
        disableClock={true}
        className={styles.picker}
        onChange={(value) => {
          console.log('onChange', value)
          if (!value) {
            onChange(null)
            return
          }
          try {
            if (isNaN(value.getTime()) || value.getFullYear() < 1000) {
              return
            }

            console.log('CallOcnahgne')
            onChange(formatISO(value))
          } catch (e) {
            console.error(e)
          }
        }}
        format={'dd.MM.y HH:mm'}
      />
      <FormHelperText
        error={!!error}
        className={!!error ? 'ra-rich-text-input-error' : ''}
      >
        <InputHelperText
          error={error as any}
          helperText={helperText}
          touched={isTouched}
        />
      </FormHelperText>
    </FormControl>
    </div>
  )
}

DateTimeInput.propTypes = {
  // @ts-ignore
  label: PropTypes.any,
  options: PropTypes.object,
  source: PropTypes.string,
  fullWidth: PropTypes.bool,
  configureQuill: PropTypes.func,
}

export default DateTimeInput