import * as React from 'react'
import {FC} from 'react'
import {
  Filter,
  List,
  TextInput,
  ListProps,
  Datagrid,
  TextField,
  SearchInput,
  BooleanInput, DateField
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import TransTextField from 'src/components/fields/TransTextField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import {getTranslation} from 'src/utils/translation'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'

const exporter = (posts: any) => {
  return csvExporter({
    columns: {
      'id': 'ID',
      'title': 'Название',
      'body': 'Содержимое',
      'published': 'Опубликовано',
      'createdAt': 'Создано',

    },
    data:  posts.map((item: any) => ({
      ...item,
      title: getTranslation(item)?.title,
      body: getTranslation(item)?.body,
      published: item.published ? 'Да' : '',
      createdAt:  formatCsvDate(item.createdAt),
      updatedAt:  formatCsvDate(item.updatedAt)
    })),
    fileName: 'Page'
  })
}

const _Filter = (props: any) => (
  <Filter {...props}>
    <TextInput source="id" label={'ID'} variant={'outlined'} />
    <SearchInput source="translations.title:$contL&key:$contL"  variant={'outlined'}  alwaysOn={true} resettable={true}/>
    <TextInput source="translations.body:$contL" label={'Содержимое'}  variant={'outlined'}  resettable={true}/>

    <BooleanInput source={'published'} label={'Опубликовано'}/>

  </Filter>
)

const PageList: FC<ListProps> = (props: any) => {
  return (
    <List
      {...props}
      exporter={exporter}
      filters={<_Filter/>}
      title="Текстовые страницы"
      empty={<EmptyList title={'Нет тестовых страниц'} description={'Вы можете добавить текстовую страницу'} buttonText={'Добавить текстовую страницу'}/>}
    >
    <Datagrid rowClick={'edit'} bulkActionButtons={false}>
      <IdShowButtonField source={'id'} label={'ID'}/>
      <VisibilityField source={'published'} label={'Опубликовано'}/>
      <TransTextField source="title" label={'Название'}/>
      <TextField source="key" label={'Ключ'}/>

      <DateField source="createdAt" label={'Создано'} showTime={true} />
      <DateField source="updatedAt" label={'Обновлено'} showTime={true} />
    </Datagrid>
    </List>
  )
}

export default PageList
