import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,
    Datagrid, SelectField, SelectInput, TextField, SearchInput,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import VisibilityField from 'src/components/fields/VisibilityField'
import AssetField from 'src/components/fields/AssetField'
import {StoryTargetList} from 'src/types/enum/StoryTarget'
import LinkField from 'src/components/fields/LinkField'

const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'Story'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>
            <SearchInput source="name||$contL" alwaysOn={true}/>
            <SelectInput source={'target'} label={'Место размещения'} choices={StoryTargetList} fullWidth variant={'outlined'} alwaysOn={true}/>

        </Filter>
    )
}

const StoryList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title="Блоки историй"
            sort={{field: 'order', order: 'ASC'}}
            empty={<EmptyList title={'Нет'} description={'Вы можете добавить'}
                              buttonText={'Добавить'}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField source={'id'} label={'ID'}/>
                <VisibilityField source="published" label={'П'}/>
                <TextField source={'name'} label={'Название'} />
                <SelectField source="target" choices={StoryTargetList} label={'Место'}/>
                <AssetField source={'image'} label={'Картинка'} />
                <TextField source={'order'} label={'Порядок'} />
                <LinkField source="storyBLock" value={'Истории'}  label={'Истории'} link={'/story-block?filter={"storyId":$id}'}/>

            </Datagrid>
        </List>
    )
}

export default StoryList
