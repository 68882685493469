import ListIcon from '@mui/icons-material/List'
import CourseAuthorList from 'src/resources/course-author/CourseAuthorList'
import CourseAuthorEdit from 'src/resources/course-author/CourseAuthorEdit'
import CourseAuthorCreate from 'src/resources/course-author/CourseAuthorCreate'

export default {
  create: CourseAuthorCreate,
  edit: CourseAuthorEdit,
  list: CourseAuthorList,
  icon: ListIcon,
}