import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,
    SearchInput,
    Datagrid, TextField, DateField,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'

const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'name': 'Название',
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'Skill'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>
            <SearchInput source={'title:$contL'} variant={'outlined'} />

        </Filter>
    )
}

const SkillList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title="Навыки"
            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'Нет'} description={'Вы можете добавить навык'}
                              buttonText={'Добавить навык'}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField source={'id'} label={'ID'}/>
                <TextField source={'title'} label={'Название'}/>
                <DateField source={'createdAt'} label={'Создан'} showTime/>
            </Datagrid>
        </List>
    )
}

export default SkillList
