import ListIcon from '@mui/icons-material/List'
import CharacterItemCategoryList from 'src/resources/character-item-category/CharacterItemCategoryList'
import CharacterItemCategoryEdit from 'src/resources/character-item-category/CharacterItemCategoryEdit'
import CharacterItemCategoryCreate from 'src/resources/character-item-category/CharacterItemCategoryCreate'

export default {
  create: CharacterItemCategoryCreate,
  edit: CharacterItemCategoryEdit,
  list: CharacterItemCategoryList,
  icon: ListIcon,
}