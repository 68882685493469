import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,
    Datagrid,
    TextField, DateField,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import ProfileLinkField from 'src/components/fields/ProfileLinkField'
import CourseLinkField from 'src/components/fields/CourseLinkField'
import ProfileInput from 'src/components/inputs/ProfileInput'
import DateRangeFilterInput from 'src/components/inputs/DateRangeFilterInput'

const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'Feedback'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>
            <ProfileInput source={'courseId'} label={'Курс'} alwaysOn resettable/>
            <ProfileInput source={'profileId'} label={'Профиль'}  resettable/>
            <DateRangeFilterInput source={'createdAt:$between'} label="Создано"
                                  alwaysOn/>
        </Filter>
    )
}

const FeedbackList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title="Отзывы"
            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'Нет'} description={'Вы можете добавить'}
                              buttonText={'Добавить'}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField source={'id'} label={'ID'}/>
                <ProfileLinkField source={'profileId'} label={'Профиль'}/>
                <CourseLinkField source={'courseId'} label={'Курс'}/>
                <TextField source={'mark'} label={'Оценка'}/>
                <TextField source={'content'} label={'Отзыв'}/>
                <DateField source={'createdAt'} label={'Создан'} showTime={true} />
            </Datagrid>
        </List>
    )
}

export default FeedbackList
