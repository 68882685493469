import * as React from 'react'
import {FC} from 'react'
import {
  Show,
  TabbedShowLayout,
  DateField,
  EditButton,
  Tab,
  EditProps,
  TextField, ReferenceField, SelectField, ArrayField, SingleFieldList, FunctionField, TopToolbar, Button,
} from 'react-admin'
import {CvProposalList} from 'src/resources/cv/CvProposalList'
import {GenderList} from 'src/types/interfaces/IProfile'

import CustomChipField from 'src/components/fields/CustomChipField'
import PhoneField from 'src/components/fields/PhoneField'
import EmailField from 'src/components/fields/EmailField'
import VisibilityField from 'src/components/fields/VisibilityField'
import {YesOrNoList} from 'src/types/enum/YesOrNo'
import {CompensationList} from 'src/types/enum/Compensation'
import { VacancyLookTypeList} from 'src/types/enum/VacancyLookType'
import {ModerationStatusList} from 'src/types/enum/ModerationStatus'
import {ModerationRejectReasonList} from 'src/types/enum/ModerationRejectReason'
import {WorkExperiencePresenseList} from 'src/types/enum/WorkExperiencePresense'
import {EducationList} from 'src/types/enum/Education'
import {RelocationList} from 'src/types/enum/Relocation'
import {ScheduleList} from 'src/types/enum/Schedule'
import {EmploymentList} from 'src/types/enum/Employment'
import {LanguageLevelList} from 'src/types/enum/LanguageLevel'
import ProfileLinkField from 'src/components/fields/ProfileLinkField'
import Formatter from 'src/utils/formatters'
import {ModalButton} from 'src/components/Modal/ModalButton'
import {CvStatusModal} from 'src/resources/cv/CvStatusModal'
import Link from '@mui/material/Link'
import {useRecordContext} from 'ra-core'

interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Резюме #{record?.id} {Formatter.formatUserName(record)}</span>

const Actions= () => {
  const recordContext = useRecordContext()
  return (
      <TopToolbar>
        <Button component={Link}  href={`https://arbihunter.com/cv/${recordContext?.id}`} label={'На сайте'} />

        <EditButton/>
        <ModalButton label={'Модерация'} modal={<CvStatusModal/>}/>
      </TopToolbar>
  )
}
const CvShow: FC<EditProps> = props => {
  return (<Show {...props} title={<Title/>} actions={<Actions/>}>
    <TabbedShowLayout>
      <Tab label="Информация">
        <TextField source={'id'} label={'ID'}/>
        <VisibilityField source={'published'} label={'Опубликован'}/>
        <FunctionField source={'cv.name'} label={'Название'} render={(i: any) => Formatter.formatCvName(i)}/>
        <SelectField source={'moderationStatus'} label={'Статус модерации'} choices={ModerationStatusList}/>
        <SelectField source={'moderationRejectReason'} label={'Причины отказа модерации'} choices={ModerationRejectReasonList}/>
        <ProfileLinkField label={'Профиль'} source={'profile'}/>
        <ReferenceField label="Пользователь" source="userId" reference="user">
          <TextField source="id"/>
        </ReferenceField>
        <TextField source={'firstName'} label={'Имя'}/>
        <TextField source={'lastName'} label={'Фамилия'}/>
        <TextField source={'patronymic'} label={'Отчество'}/>
        <TextField source={'birthday'} label={'ДР'}/>
        <TextField source={'age'} label={'Возраст'}/>
        <SelectField source={'gender'} label={'Пол'} choices={GenderList}/>
        <SelectField source={'workExperiencePresense'} label={'Опыт'} choices={WorkExperiencePresenseList}/>
        <SelectField source={'education'} label={'Обучение'} choices={EducationList}/>
        <SelectField source={'relocation'} label={'Переезд'} choices={RelocationList}/>
        <SelectField source={'businessTrips'} label={'Готовность к командировкам'} choices={YesOrNoList}/>
        <TextField source={'position'} label={'Позиция'}/>
        <TextField source={'bio'} label={'BIO'}/>
        <TextField source={'portfolio'} label={'Портфолио'}/>
        <SelectField source={'schedule'} label={'График работы'} choices={ScheduleList}/>
        <SelectField source={'employment'} label={'Тип найма'} choices={EmploymentList}/>
        <ArrayField source="categories"  label={'Категории'}>
          <SingleFieldList>
            <CustomChipField render={(i: any) => `${i.name}`}/>
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="skills"  label={'Навыки'}>
          <SingleFieldList>
            <CustomChipField render={(i: any) => `${i.title}`}/>
          </SingleFieldList>
        </ArrayField>
        <ArrayField source="sources"  label={'Источники'}>
          <SingleFieldList>
            <CustomChipField render={(i: any) => i.title}/>
          </SingleFieldList>
        </ArrayField>
        <TextField source={'salary'} label={'Зарплата'}/>
        <TextField source={'commission'} label={'Процент'}/>
        <SelectField source={'compensation'} label={'Вид оплаты'} choices={CompensationList}/>
        <SelectField source={'vacancyLookType'} label={'Состояние резюме'} choices={VacancyLookTypeList}/>
        <TextField source={'currency'} label={'Валюта'}/>


        <TextField source={'nativeLanguage'} label={'Основной язык'}/>
        <ArrayField source="languageKnowledges"  label={'Языки'}>
          <SingleFieldList>
            <CustomChipField render={(i: any) => `${i.language} - ${LanguageLevelList.find((a: any) => a.id === i.level)?.name}`}/>
          </SingleFieldList>
        </ArrayField>


        <DateField source={'createdAt'} label={'Создано'} showTime={true} />
        <TextField source={'telegramNickname'} label={'Ник ТГ'}/>
        <TextField source={'country.name'} label={'Страна'}/>
        <TextField source={'city.name'} label={'Город'}/>
        <PhoneField source={'phone'} label={'Телефон'}/>
        <EmailField source={'email'} label={'Email'}/>
        <FunctionField source={'isTop'} label={'Вип'} render={(i: any) => `${i.isTop ? 'Активирован' : 'Не активен'}`}/>
        <DateField source={'topPurchasedAt'} label={'Время покупки вип'} showTime={true} />
      </Tab>
      <Tab label="Приглашения" path="proposal">
        <CvProposalList />
      </Tab>
    </TabbedShowLayout>
  </Show>)
}

export default CvShow
