import * as React from 'react'
import {FC, useRef} from 'react'
import {
    DateTimeInput,
    required,
    TextInput,
} from 'react-admin'
import Box from '@mui/material/Box'
import {IFocalPoint} from 'src/components/types'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import {MediaInput} from 'src/components/inputs/MediaInput'
type UpdateCallback = (value: IFocalPoint | null) => void
interface ProductTitleProps {
    record?: any;
}
export const StoryBlockEditForm: FC<any> = props => {
    const {record} = props
    const currentEditRef = useRef()
    return (
        <Box >

            <TextInput source="title" label={'Заголовок'} fullWidth variant={'outlined'}/>
            <DateTimeInput
                label="Время публикации"
                source="publishedAt"
                variant={'outlined'}
                fullWidth={true}
            />
            <RichTextInput source={'body'} label={'Описание'} variant={'outlined'} fullWidth={true}/>
            <MediaInput source={'image'} label={'Видео'}
                        accept={['image/jpeg', 'image/png', 'image/svg+xml', 'video/*']}/>

        </Box>
           
    )
}
const requiredValidate = [required()]


