import * as React from 'react'
import {ChangeEventHandler, FC, useRef} from 'react'
import {
    ArrayInput, BooleanInput, DateTimeInput, NumberInput,
    required, SimpleFormIterator,
    TextInput,
} from 'react-admin'
import Box from '@mui/material/Box'
import {IFocalPoint} from 'src/components/types'
import {MediaInput} from 'src/components/inputs/MediaInput'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import CommunityInput from 'src/components/inputs/CommunityInput'
import ProfileInput from 'src/components/inputs/ProfileInput'
import Formatter from 'src/utils/formatters'
import {useFormContext} from 'react-hook-form'
type UpdateCallback = (value: IFocalPoint | null) => void
interface ProductTitleProps {
    record?: any;
}
export const CourseArticleEditForm: FC<any> = props => {
    const {record} = props
    const form = useFormContext()
    const currentEditRef = useRef()
    const handleNameChange: ChangeEventHandler<HTMLInputElement> = (e) => {
        if (props.record?.id) {
            return
        }
        form.setValue('slug', Formatter.slug(e.currentTarget.value))
    }
    return (
        <Box >

            <BooleanInput source="published" label={'Опубликовано'} variant={'outlined'}/>
            <TextInput source={'name'} label={'Название'} onChange={handleNameChange}  variant={'outlined'} validate={required()} fullWidth={true}/>
            <BooleanInput source={'isTop'} label={'Топ'}  variant={'outlined'}  fullWidth={true}/>

            <DateTimeInput
                label="Время публикации"
                source="publishedAt"
                variant={'outlined'}
                fullWidth={true}
            />

            <MediaInput source={'image'} label={'Картинка'} />
            <MediaInput isMulti source={'images'} label={'Несколько картинок'} />
            <ArrayInput source="blocks" label={'Текст блоки'}>
                <SimpleFormIterator fullWidth>
                    <TextInput source="title"  label={'Заголовок'} fullWidth/>
                    <RichTextInput source={'body'} label={'Текст'} variant={'outlined'} validate={required()} fullWidth={true}/>
                </SimpleFormIterator>
            </ArrayInput>
            <NumberInput source={'minutesToRead'} multiline={true} label={'Минут на чтение'} variant={'outlined'}  fullWidth={true}/>
            <CommunityInput source={'communityId'} label={'Сообщество'} resettable/>
            <ProfileInput source={'profileId'} label={'Профиль'} resettable/>

        </Box>

    )
}
const requiredValidate = [required()]


