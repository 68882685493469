import ListIcon from '@mui/icons-material/List'
import CommunityList from 'src/resources/community/CommunityList'
import CommunityEdit from 'src/resources/community/CommunityEdit'
import CommunityCreate from 'src/resources/community/CommunityCreate'
import CommunityShow from 'src/resources/community/CommunityShow'

export default {
  create: CommunityCreate,
  edit: CommunityEdit,
  list: CommunityList,
  show: CommunityShow,
  icon: ListIcon,
}
