import * as React from 'react'
import {FC} from 'react'
import {
  Show,
  TabbedShowLayout,
  DateField,
  Tab,
  EditProps,
  TextField,
} from 'react-admin'
import ProfileLinkField from 'src/components/fields/ProfileLinkField'
import AssetField from 'src/components/fields/AssetField'
import {ArticleList} from 'src/resources/community/components/ArticleList'
import {CommunityUserList} from 'src/resources/community/components/CommunityUserList'
interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = ({record}) =>
 <span>Сообществао #{record?.id} {record?.name}</span>

const ProfileShow: FC<EditProps> = props => {
  return (<Show {...props} title={<Title/>}>
    <TabbedShowLayout>
      <Tab label="Информация">
        <AssetField source={'logo'} label={'Лого'}/>
        <TextField source={'name'} label={'Название'}/>
        <TextField source={'membersCount'} label={'Кол-во участников'}/>
        <AssetField source={'image'} label={'Картинка'}/>
        <ProfileLinkField source={'profile'} label={'Профиль'}/>
        <DateField source="createdAt" label={'Регистрация'} showTime={true} />
      </Tab>
      <Tab label="Посты" path="vacancies">
        <ArticleList />
      </Tab>
      <Tab label="Участники" path="participants">
        <CommunityUserList />
      </Tab>


    </TabbedShowLayout>
  </Show>)
}

export default ProfileShow
