import {AutocompleteArrayInput, ReferenceArrayInput, FilterPayload} from 'react-admin'
import * as React from 'react'
import {getTranslation} from 'src/utils/translation'


interface Props {
    source: string
    label?: string
    disabled?: boolean;
    allowEmpty?: boolean
    fullWidth?: boolean
    filter?: FilterPayload
}

const CategoriesArrayInput = (props: Props) => {
    return (<ReferenceArrayInput
        allowEmpty={false} source={props.source} reference="service-category"
        variant={'outlined'} fullWidth={true}
        perPage={500}
        filter={props.filter}
    >
        <AutocompleteArrayInput label={props.label} noOptionsText="-"  filterToQuery={(q: string) => ({'translations.name:$contL': q, 'translations.languageCode': 'ru'})}   source="id"  optionText={i => i ? `${getTranslation(i)?.name}` : ''} disabled={props.disabled} fullWidth={props.fullWidth}/>
    </ReferenceArrayInput>)
}

export default CategoriesArrayInput