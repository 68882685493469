import {MultiLevelMenu} from '@glm/ra-navigation'
import {
    Cogs,
    Api,
    Newspaper,
    ChartDonutVariant,
    NoteText,
    AccountTieHat,
    ListBox,
    AccountGroup,
    CommentQuote,
    Instagram,
    FormatListGroup,
    School,
    Typewriter,
    FileAccount,
    Chat,
    Hanger,
    FormatListBulleted,
    Multimedia,
    Bell,
    ShieldAccount,
    CloseOctagon,
    SourceFork
} from 'mdi-material-ui'
import React from 'react'

export const MyMenu = () => (
    <MultiLevelMenu>
        <MultiLevelMenu.Item name="article-news" to={'/article-news'} label="Контент" icon={<Newspaper/>}>
            <MultiLevelMenu.Item name="article-news" to="/article-news" label="Статьи" icon={<Newspaper/>}/>
            <MultiLevelMenu.Item name="article-category-news" to="/article-category-news" label="Категории статей"
                                 icon={<FormatListGroup/>}/>
            <MultiLevelMenu.Item name="article-knowledge" to="/article-knowledge" label="База знаний"
                                 icon={<ChartDonutVariant/>}/>
            <MultiLevelMenu.Item name="article-category-knowledge" to="/article-category-knowledge" label="Категории БЗ"
                                 icon={<FormatListGroup/>}/>
            <MultiLevelMenu.Item name="story-block" to="/story-block" label="Истории" icon={<Instagram/>}/>
            <MultiLevelMenu.Item name="story" to="/story" label="Блоки историй" icon={<Instagram/>}/>

            <MultiLevelMenu.Item name="page" to="/page" label="Текстовые страницы" icon={<NoteText/>}/>
            <MultiLevelMenu.Item name="asset" to="/asset" label="Медиа" icon={<Multimedia/>}/>
        </MultiLevelMenu.Item>
        <MultiLevelMenu.Item name="skill" to={'/skill'} label="Настройки" icon={<Cogs/>}>
            <MultiLevelMenu.Item name="skill" to="/skill" label="Навыки" icon={<FormatListBulleted/>}/>
            <MultiLevelMenu.Item name="service-category" to="/service-category" label="Категории работ"
                                 icon={<FormatListBulleted/>}/>
            <MultiLevelMenu.Item name="stopword" to="/stopword" label="Стоп-слова" icon={<CloseOctagon/>}/>
            <MultiLevelMenu.Item name="source" to="/source" label="Источники" icon={<SourceFork/>}/>
            <MultiLevelMenu.Item name="character-item" to="/character-item" label="Вещи" icon={<Hanger/>}/>
            <MultiLevelMenu.Item name="character-item-category" to="/character-item-category" label="Категории вещей"
                                 icon={<FormatListBulleted/>}/>
            <MultiLevelMenu.Item name="background" to="/background" label="Фоны" icon={<Hanger/>}/>
            <MultiLevelMenu.Item name="background-category" to="/background-category" label="Категории фонов"
                                 icon={<FormatListBulleted/>}/>
            <MultiLevelMenu.Item name="vertical" to="/vertical" label="Вертикали" icon={<CloseOctagon/>}/>
            <MultiLevelMenu.Item name="tariff" to="/tariff" label="Тарифы" icon={<FormatListBulleted/>}/>

        </MultiLevelMenu.Item>

        <MultiLevelMenu.Item name="course" to={'/course'} label="Курсы" icon={<School/>}>
            <MultiLevelMenu.Item name="course" to="/course" label="Курсы" icon={<School/>}/>

            <MultiLevelMenu.Item name="course-category" to="/course-category" label="Категории курсов"
                                 icon={<ListBox/>}/>
            <MultiLevelMenu.Item name="course-compilation-category" to="/course-compilation-category"
                                 label="Подборки курсов" icon={<ListBox/>}/>
            <MultiLevelMenu.Item name="course-author" to="/course-author" label="Авторы курсов" icon={<Typewriter/>}/>
        </MultiLevelMenu.Item>

        <MultiLevelMenu.Item name="profile" to={'/profile'} label="Профили" icon={<AccountTieHat/>}>
            <MultiLevelMenu.Item name="profile" to="/profile" label="Все профили" icon={<AccountTieHat/>}/>
            <MultiLevelMenu.Item name="profile-hirer" to="/profile-hirer" label="Работодатели" icon={<AccountTieHat/>}/>
            <MultiLevelMenu.Item name="profile-employee" to="/profile-employee" label="Соискатели"
                                 icon={<AccountTieHat/>}/>
            {/*<MultiLevelMenu.Item name="user" to="/user" label="Пользователи" icon={<AccountTieHat/>}/>*/}
            {/*<MultiLevelMenu.Item name="character" to="/character" label="Перс. страницы" icon={<AccountCowboyHat/>}/>*/}

        </MultiLevelMenu.Item>
        <MultiLevelMenu.Item name="chat" to={'/chat'} label="Активность" icon={<Chat/>}>
            <MultiLevelMenu.Item name="chat" to="/chat" label="Чаты" icon={<Chat/>}/>
            <MultiLevelMenu.Item name="feedback" to="/feedback" label="Отзывы" icon={<CommentQuote/>}/>
        </MultiLevelMenu.Item>
        <MultiLevelMenu.Item name="community" to="/community" label="Сообщества" icon={<AccountGroup/>}/>

        <MultiLevelMenu.Item name="hr" to={'/hr'} label="Hr" icon={<AccountTieHat/>}>
            <MultiLevelMenu.Item name="cv" to="/cv" label="Резюме" icon={<FileAccount/>}>
                <MultiLevelMenu.Item name="cv-approved" to="/cv-approved" label="Одобрено" icon={<FileAccount/>}/>
                <MultiLevelMenu.Item name="cv-moderation" to="/cv-moderation" label="Модерация" icon={<FileAccount/>}/>
            </MultiLevelMenu.Item>
            <MultiLevelMenu.Item name="vacancy" to="/vacancy" label="Вакансии" icon={<ChartDonutVariant/>}>
                <MultiLevelMenu.Item name="vacancy-approved" to="/vacancy-approved" label="Одобрено"
                                     icon={<ChartDonutVariant/>}/>
                <MultiLevelMenu.Item name="vacancy-moderation" to="/vacancy-moderation" label="Модерация"
                                     icon={<ChartDonutVariant/>}/>
            </MultiLevelMenu.Item>
            <MultiLevelMenu.Item name="application" to="/application" label="Отклики" icon={<ChartDonutVariant/>}/>
            <MultiLevelMenu.Item name="proposal" to="/proposal" label="Приглашения" icon={<ChartDonutVariant/>}/>
        </MultiLevelMenu.Item>
        <MultiLevelMenu.Item name="notification" to="/notification" label="Уведомления" icon={<Api/>}/>
        <MultiLevelMenu.Item name="notification-admin" to="/notification-admin" label="Рассылки" icon={<Bell/>}/>
        <MultiLevelMenu.Item name="admin" to="/admin" label="Администраторы" icon={<ShieldAccount/>}/>


    </MultiLevelMenu>
)
