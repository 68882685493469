
export enum CourseStepType {
    Info = 'info',
    Question = 'question',
}

export const CourseStepTypeList = [
    {id: CourseStepType.Info, name: 'Задание'},
    {id: CourseStepType.Question, name: 'Тест'},
]

export enum CourseAnswerType {
    Single = 'single',
    Multiple = 'multiple',
}

export const CourseAnswerTypeList = [
    {id: CourseAnswerType.Single, name: 'Один правильный ответ'},
    {id: CourseAnswerType.Multiple, name: 'Несколько правильных ответов'},
]
