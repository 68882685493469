import * as React from 'react'
import {FC} from 'react'
import {
  Create,
  EditProps,
} from 'react-admin'
import CharacterItemCategoryForm from 'src/resources/character-item-category/CharacterItemCategoryForm'

interface TitleProps {
  record?: any;
}

const Title = (props: TitleProps) =>
 <span>Новая Категория вещей</span>

const CharacterItemCategoryCreate: FC<EditProps> = props => {
  return (
    <Create {...props} redirect="list"title={<Title/>}>
      <CharacterItemCategoryForm/>
    </Create>
  )
}

export default CharacterItemCategoryCreate