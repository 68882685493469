import ListIcon from '@mui/icons-material/List'
import ArticleCategoryList from 'src/resources/article-category/ArticleCategoryList'
import ArticleCategoryEdit from 'src/resources/article-category/ArticleCategoryEdit'
import ArticleCategoryCreate from 'src/resources/article-category/ArticleCategoryCreate'

export default {
  create: ArticleCategoryCreate,
  edit: ArticleCategoryEdit,
  list: ArticleCategoryList,
  icon: ListIcon,
}