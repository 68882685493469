import * as React from 'react'
import {
  Pagination,
  DateField,
  Datagrid,
  TextField,
  ReferenceManyField,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import AssetField from 'src/components/fields/AssetField'

export const ProfileCommunityList = (props: any) => {
  const {record} = props
  return (
    <ReferenceManyField {...props} reference="community" target="profileId" addLabel={false}
                        sort={{field: 'id', order: 'DESC'}}  pagination={<Pagination/>} perPage={50}>
      <Datagrid>
        <IdShowButtonField source={'id'} label={'ID'} type={'show'}/>
        <AssetField source={'logo'} label={'Лого'}/>
        <TextField source={'name'} label={'Название'}/>
        <TextField source={'membersCount'} label={'Кол-во участников'}/>
        <AssetField source={'image'} label={'Картинка'}/>
        <DateField source="createdAt" label={'Регистрация'} showTime={true} />
      </Datagrid>
    </ReferenceManyField>)
}
