import ListIcon from '@mui/icons-material/List'
import BackgroundList from 'src/resources/background/BackgroundList'
import BackgroundEdit from 'src/resources/background/BackgroundEdit'
import BackgroundCreate from 'src/resources/background/BackgroundCreate'

export default {
  create: BackgroundCreate,
  edit: BackgroundEdit,
  list: BackgroundList,
  icon: ListIcon,
}