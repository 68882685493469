import ListIcon from '@mui/icons-material/List'
import BackgroundCategoryList from 'src/resources/background-category/BackgroundCategoryList'
import BackgroundCategoryEdit from 'src/resources/background-category/BackgroundCategoryEdit'
import BackgroundCategoryCreate from 'src/resources/background-category/BackgroundCategoryCreate'

export default {
  create: BackgroundCategoryCreate,
  edit: BackgroundCategoryEdit,
  list: BackgroundCategoryList,
  icon: ListIcon,
}