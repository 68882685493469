import * as React from 'react'
import {
    SimpleForm, TextInput, DateTimeInput, required,
} from 'react-admin'
import {RichTextInput} from 'src/components/inputs/RichTextInput'
import {MediaInput} from 'src/components/inputs/MediaInput'
import StoryInput from 'src/components/inputs/StoryInput'

const StoryBlockForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
        <StoryInput source={'storyId'} label='Блок'  validate={[required()]} fullWidth/>

        <TextInput source="title" label={'Заголовок'} fullWidth variant={'outlined'}/>
      <DateTimeInput
          label="Время публикации"
          source="publishedAt"
          variant={'outlined'}
          fullWidth={true}
          validate={[required()]}
      />
      <RichTextInput source={'body'} label={'Описание'} variant={'outlined'} fullWidth={true}/>
      <MediaInput source={'image'} label={'Видео'} validate={[required()]}
                  accept={['image/jpeg', 'image/png', 'image/svg+xml', 'video/*']}/>
    </SimpleForm>
  )
}
export default StoryBlockForm
