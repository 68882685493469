import * as React from 'react'
import {
  TabbedForm,
  FormTab, ArrayInput, SimpleFormIterator, TextInput, required,
} from 'react-admin'
import {useFormContext} from 'react-hook-form'
import {RichTextInput} from 'src/components/inputs/RichTextInput'

const InfoTab = (props: any) => {
  const form = useFormContext()
  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>
    <ArrayInput source="blocks" label={'Блоки'}>
      <SimpleFormIterator fullWidth>
        <TextInput source="title"  label={'Заголовок'} fullWidth variant={'outlined'}/>
        <RichTextInput source={'text'} label={'Текст'} variant={'outlined'} validate={required()} fullWidth={true}/>
      </SimpleFormIterator>
    </ArrayInput>
    </FormTab>)
}
const PersonalPageForm = (props: any) => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
    </TabbedForm>
  )
}
export default PersonalPageForm
