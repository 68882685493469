import React, {isValidElement, Children, useState, useEffect, ReactElement} from 'react'
import {
    DatagridHeaderCell,
    useCreatePath,
    useDataProvider,
    useListContext,
    useRefresh,
    useResourceContext
} from 'react-admin'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import {listToTree} from './utils'
import {INode} from './types'
/* --- Example ---
  <List {...props} title="Clubs" pagination={null}>
    <TreeDatagrid>
      <TextField source="name" label="Name" />
      <TextField source="city" label="City" />
    </TreeDatagrid>
  </List>
*/




interface Props {
    children: React.ReactNode
    ids?: number[]
    data?: any
    resource?: string
    basePath?: string
    currentSort?: any
    setSort?: any,

}

function TreeDatagrid(props: Props) {
    const dataProvider = useDataProvider()
    const list = useListContext()
    const resource = useResourceContext()
    const createPath = useCreatePath()
    const refresh = useRefresh()
     const [openedIds, setOpenedIds] = useState<number[]>([])
    const [parentIndexes, setParentIndexes] = useState<any>({})
    const [tree, setTree] = useState<any[]>([])
    const [localData, setLocalData] = useState<any[]>([])
    useEffect(() => {
       if(list.data){
           setLocalData(list.data)
           setTree(listToTree(list.data))
       }
    }, [list.data])

    const calculateParentIndexes = (_openedIds: number[], _tree?: any[]) => {
        const calcTotalOpened = (record: any, count: number = 0) => {
            if (record.children.length > 0 && _openedIds.includes(record.id)) {
                count += record.children.length
                console.log('addCountS', record.id, count)
                for (const item of record.children) {
                    const addCount = calcTotalOpened(item, 0)
                    console.log('addCount', item.id, count, addCount)
                    count += addCount
                }
                return count
            }
            return 0
        }
        const opened: any = {}
        let count = 0
        for (const item of (_tree ? _tree : tree)) {

            opened[item.id] = count
            count += calcTotalOpened(item, 0)
            ++count
        }
        console.log('Indexes', opened)
        setParentIndexes(opened)

    }


    // eslint-disable-next-line react/display-name
    const handleClick = (record: INode) => () => {
        console.log('Click', record)
        if (record.children?.length === 0) {
            return null
        }
        let _openedIds: any[] = []
        const calcTotalOpened = (record: any, count: number = 0) => {
            if (record.children.length > 0 && _openedIds.includes(record.id)) {
                count += record.children.length
                console.log('addCountS', record.id, count)
                for (const item of record.children) {
                    const addCount = calcTotalOpened(item, 0)
                    console.log('addCount', item.id, count, addCount)
                    count += addCount
                }
                return count
            }
            return 0
        }

        const exist = openedIds.includes(record.id)
        const opened = {}
        let count = 0


        if (exist) {
            _openedIds = openedIds.filter(item => item !== record.id)
            setOpenedIds(_openedIds)
        } else {
            _openedIds = [...openedIds, record.id]
            setOpenedIds(_openedIds)
        }
        calculateParentIndexes(_openedIds)
    }

    const getRow = (record: INode, isOpened: boolean, lvl: number, index?: number): ReactElement => {
        const hasChildren: boolean = !!record.children?.length ?? false
        const visibleSubRows: boolean = isOpened && hasChildren
        console.log('RowIndex', index)
        return (
            <>
                <TableRow
                    key={record.id}>
                    {Children.map(props.children, (field, index) => {
                        const isName = (field as any)?.props?.source === 'name'
                        return isValidElement(field) ? (
                            <TableCell key={index}>
                                <div
                                    style={{paddingLeft: isName ? 20 * lvl : 0, display: 'flex', alignItems: 'center'}}>
                                    {isName && hasChildren && (
                                        visibleSubRows ? <ArrowDropUpIcon onClick={handleClick(record)}/> :
                                            <ArrowDropDownIcon onClick={handleClick(record)}/>
                                    )}

                                        {React.cloneElement(field, {
                                            record,
                                            basePath: field.props.basePath || props.basePath,
                                            resource: props.resource,
                                        })}

                                </div>
                            </TableCell>
                        ) : null
                    })}
                </TableRow>
                {visibleSubRows && record.children?.map((subRow, idx) => getRow(subRow, openedIds.includes(subRow.id), lvl + 1, idx + 1 + (index ?? 0)))}
            </>
        )
    }


    return (
        <div>
            <Table>
                <TableHead>
                    <TableRow>
                        {Children.map(props.children, (field, index) =>
                            isValidElement(field) ? (
                                <DatagridHeaderCell
                                    field={field}
                                    sort={list.sort}
                                    resource={resource}
                                    isSorting={false}
                                    key={field.props.source || index}
                                />
                            ) : null,
                        )}
                    </TableRow>
                </TableHead>
                <TableBody>

                    {tree.map((record, idx) => getRow(record, openedIds.includes(record.id), 0, parentIndexes[record.id] || idx))}
                </TableBody>

            </Table>
        </div>
    )
}

export default TreeDatagrid
