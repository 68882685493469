import ListIcon from '@mui/icons-material/List'
import StoryList from 'src/resources/story/StoryList'
import StoryEdit from 'src/resources/story/StoryEdit'
import StoryCreate from 'src/resources/story/StoryCreate'

export default {
  create: StoryCreate,
  edit: StoryEdit,
  list: StoryList,
  icon: ListIcon,
}