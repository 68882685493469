import * as React from 'react'
import {
  FormTab,
  required,
  SelectInput,
  NumberInput,
  TabbedForm,
  BooleanInput,
  TextInput, ArrayInput, SimpleFormIterator, ReferenceInput, ReferenceArrayInput, AutocompleteArrayInput,
} from 'react-admin'
import {getTranslation} from 'src/utils/translation'
import {MediaInput} from 'src/components/inputs/MediaInput'
import {LangList} from 'src/types'
const Form = (props: any) => {
  return (
      <FormTab
        {...props}
        label="Категория"
      >
        {props.isNew && <ReferenceInput
            source="parentId"
            reference="service-category"
            sort={{ field: 'translations.name', order: 'ASC' }}
            filter={{'translations.languageCode': 'ru'}}
        >
          <SelectInput source="name"       variant={'outlined'}
                       label={'Родительская категория'}
                       fullWidth optionText={(i: any) => getTranslation(i)?.name}/>
        </ReferenceInput>}
        <BooleanInput source={'showOnMain'} label={'Показывать на главной'} variant={'outlined'} />
        <BooleanInput source={'withSources'} label={'C источниками'} variant={'outlined'} />
        <BooleanInput source={'withGeos'} label={'С гео'} variant={'outlined'} />
        <MediaInput source={'image'} label={'Картинка'} />
        <TextInput source="color" label={'Цвет'} variant={'outlined'} fullWidth/>
        <NumberInput source={'sort'} label={'Сортировка'} variant={'outlined'} />

        <ReferenceArrayInput
            sort={{field: 'title', order: 'ASC'}}
            allowEmpty={true} source="skillsIds" reference="skill"
            variant={'outlined'} fullWidth={true} perPage={50}
        >
          <AutocompleteArrayInput fullWidth={true}   label="Скиллы"
                                  filterToQuery={(q: string) => ({'title:$contL': q})}
                                  optionText={(record) => record?.title} />
        </ReferenceArrayInput>
        <ArrayInput source="translations" label={'Контент'}>
          <SimpleFormIterator fullWidth>
            <SelectInput source="languageCode" label={'Язык'} variant={'outlined'} choices={LangList} validate={required()} />
            <TextInput source="name" label={'Название'} variant={'outlined'} fullWidth validate={required()} />
            <TextInput source="description" label={'Описание'} multiline variant={'outlined'} fullWidth  />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
  )
}

export const ServiceCategoryForm = (props: any) => {
  return (
    <TabbedForm {...props} redirect={'list'}>
     <Form isNew={props.isNew}/>

    </TabbedForm>
  )
}

const requiredValidate = [required()]
