import * as React from 'react'
import { BooleanInput, SelectInput, NumberInput, TabbedForm, FormTab, required,
} from 'react-admin'
import {MediaInput} from 'src/components/inputs/MediaInput'
import {StoryTargetList} from 'src/types/enum/StoryTarget'
import {useFormContext} from 'react-hook-form'
import {StoryBlockList} from 'src/resources/story/story-block/StoryBlockList'

const InfoTab = (props: any) => {
    const form = useFormContext()
    return (<FormTab
        label="Информация"
        path={'benefit'}
        {...props}>
        <BooleanInput source="published" label={'Опубликовано'} variant={'outlined'}/>
        <SelectInput source={'target'} label={'Место размещения'} choices={StoryTargetList} fullWidth
                     variant={'outlined'} validate={[required()]}/>
        <BooleanInput source="name" label={'Название'} variant={'outlined'} validate={[required()]}/>
        <MediaInput source={'image'} label={'Превью'} accept={['image/jpeg', 'image/png', 'image/svg+xml']}/>
        <NumberInput source="order" label={'Порядок'} variant={'outlined'}/>
    </FormTab>)
}

const StoryBlockTab = (props: any) => {
    return (<FormTab {...props} label={'Истории'}>
        <StoryBlockList {...props}/>
    </FormTab>)
}
const StoryForm = (props: any) => {
    return (
        <TabbedForm {...props} redirect={'list'}>
            <InfoTab/>
        </TabbedForm>
    )
}
export default StoryForm
