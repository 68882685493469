
export enum PersonalPageBlockType {
    Text = 'text',
    Image = 'image',
    Gallery = 'gallery',
    Video = 'video',
    Location = 'location',
    Separator = 'separator',
}

export const PersonalPageBlockTypeList = [
    {id: PersonalPageBlockType.Text, name: 'Текст'},
    {id: PersonalPageBlockType.Image, name: 'Картинка'},
    {id: PersonalPageBlockType.Gallery, name: 'Галерея фото'},
    {id: PersonalPageBlockType.Video, name: 'Видео'},
    {id: PersonalPageBlockType.Location, name: 'Адрес'},
    {id: PersonalPageBlockType.Separator, name: 'Разделитель'},
]