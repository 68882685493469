import * as React from 'react'
import {FieldProps, RaRecord} from 'react-admin'
import {Typography} from '@mui/material'
import Formatter from 'src/utils/formatters'
import {useRecordContext} from 'ra-core'
interface Props<RecordType extends RaRecord = any> extends FieldProps {
}

const ProfileBalanceField = (props: FieldProps) => {
  const record = useRecordContext(props)
  const price = record?.user?.owner?.balance ?? record?.user?.balance

  return (<Typography variant={'body2'} sx={{whiteSpace: 'nowrap'}}>{ typeof price === 'undefined' ? '' : Formatter.formatPrice(price)}</Typography>)
}

export default ProfileBalanceField
