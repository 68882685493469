import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    SelectField,
    ListProps,
    Datagrid, TextField, ReferenceField, ReferenceInput, SelectInput,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import EmptyList from 'src/components/list/EmptyList'
import AssetField from 'src/components/fields/AssetField'
import {ProfileTypeList} from 'src/types/interfaces/IProfile'
import {BackgroundTypeList} from 'src/types/enum/BackgroundType'
import TransTextField from 'src/components/fields/TransTextField'
import {getTranslation} from 'src/utils/translation'


const _Filter = (props: any) => {
    return (
        <Filter {...props}>

            <ReferenceInput alwaysOn={true}  source="categoryId" reference="background-category"  variant={'outlined'}
                             filter={{'translations.languageCode': 'ru'}}
                             sort={{field: 'translations.name', order: 'ASC'}} perPage={100} >
                <SelectInput  fullWidth={true}   label="Категория"
                              optionText={(record) => `${getTranslation(record)?.name}`} resettable={true} />
            </ReferenceInput>
            <SelectInput source={'backgroundType'} label={'Тип фона'} choices={BackgroundTypeList} fullWidth variant={'outlined'}/>
            <SelectInput source={'profileType'} label={'Тип профиля'} choices={ProfileTypeList} fullWidth variant={'outlined'}/>

        </Filter>
    )
}

const BackgroundList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={false}
            filters={<_Filter/>}
            title="Фоны"
            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'Нет'} description={'Вы можете добавить'}
                              buttonText={'Добавить'}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField source={'id'} label={'ID'}/>
                <ReferenceField label="Категория" source="categoryId" reference="background-category">
                    <TransTextField source="name"/>
                </ReferenceField>
                <SelectField source={'profileType'} label={'Тип Профиля'} choices={ProfileTypeList}/>
                <SelectField source={'backgroundType'} label={'Тип файла'} choices={BackgroundTypeList} />
                <AssetField source={'image'} label={'Картинка'} />
                <TextField source={'order'} label={'Сортировка'}/>

            </Datagrid>
        </List>
    )
}

export default BackgroundList
