import * as React from 'react'
import {
  TabbedForm,
  FormTab,
  ArrayInput,
  SimpleFormIterator,
  SelectInput,
  required,
  TextInput,
  NumberInput,
  BooleanInput,
  ReferenceInput
} from 'react-admin'
import {useFormContext} from 'react-hook-form'
import {getTranslation} from 'src/utils/translation'
import {MediaInput} from 'src/components/inputs/MediaInput'
import {GenderList} from 'src/types/interfaces/IProfile'
import {LangList} from 'src/types'

const InfoTab = (props: any) => {
  const form = useFormContext()
  return (<FormTab
    label="Информация"
    path={'benefit'}
    {...props}>

    <ReferenceInput  source="categoryId" reference="character-item-category"  variant={'outlined'}
                     filter={{'translations.languageCode': 'ru'}}
                     sort={{field: 'translations.name', order: 'ASC'}} perPage={100}  validate={[required()]}>
      <SelectInput  fullWidth={true} validate={required()}  label="Категория"
                    optionText={(record) => `${getTranslation(record)?.name}`} />
    </ReferenceInput>
    <MediaInput source={'image'} label={'Картинка'} />
    <TextInput source="animationKey" helperText={'Ключ для Rive анимации'} label={'Ключ'} variant={'outlined'} fullWidth  />
    <SelectInput source={'gender'} label={'Пол'} choices={GenderList}/>
    <ArrayInput source="translations" label={'Контент'}>
      <SimpleFormIterator fullWidth>
        <SelectInput source="languageCode" label={'Язык'} variant={'outlined'} choices={LangList} validate={required()} />
        <TextInput source="name" label={'Название'} variant={'outlined'} fullWidth validate={required()} />
      </SimpleFormIterator>
    </ArrayInput>
    <NumberInput source="price" label={'Цена'} variant={'outlined'} fullWidth validate={required()} />
    <BooleanInput source={'isNewItem'} label={'Новое'}/>

  </FormTab>)
}
const CharacterItemForm = (props: any) => {
  return (
    <TabbedForm {...props} redirect={'list'}>
      <InfoTab/>
    </TabbedForm>
  )
}
export default CharacterItemForm
