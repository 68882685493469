import * as React from 'react'
import {FC} from 'react'
import {
  Show,
  TabbedShowLayout,
  DateField,
  Tab,
  EditProps,
  TextField, SelectField,
} from 'react-admin'
import {ProfileType, ProfileTypeList} from 'src/types/interfaces/IProfile'
import {UserVacancyList} from 'src/resources/profile/cv/UserVacancyList'
import {UserCvList} from 'src/resources/profile/cv/UserCvList'
import Formatter from 'src/utils/formatters'
import {ProfileManagerList} from 'src/resources/profile/cv/ProfileManagerList'
import ProfileLinkField from 'src/components/fields/ProfileLinkField'
import {useRecordContext} from 'ra-core'
import {ProfileCommunityList} from 'src/resources/profile/cv/ProfileCommunityList'
import ProfileBalanceField from 'src/components/fields/ProfileBalanceField'
import {HirerRoles} from 'src/types/enum/HirerRole'
interface TitleProps {
  record?: any;
}

const Title: FC<TitleProps> = () => {
  const record = useRecordContext()
  return (<span>Профиль #{record?.id} {record && Formatter.formatProfileName(record)}</span>)
}

const Layout = () => {
  const record = useRecordContext()
  return (<TabbedShowLayout>
    <Tab label="Информация">
      <TextField source={'id'} label={'ID'}/>
      <SelectField source={'profileType'} label={'Активность'} choices={ProfileTypeList}/>
      <ProfileLinkField source={'lastName'} format={'userName'} label={'ФИО'}/>
      <TextField source={'email'} label={'Email'}/>
      <SelectField source={'hirerRole'} label={'Роль'} choices={HirerRoles}/>
      <ProfileBalanceField source={'user'} label={'Баланс'}/>
      <TextField source={'telegramNickname'} label={'Ник ТГ'}/>
      <TextField source={'company'} label={'Компания'}/>
      <TextField source={'country.name'} label={'Страна'}/>
      <TextField source={'city.name'} label={'Город'}/>
      <DateField source={'lastActivityAt'} label={'Активность'} showTime/>
      <DateField source={'createdAt'} label={'Регистрация'} showTime={true} />
    </Tab>
    {record?.profileType === ProfileType.Hirer && <Tab label="Вакансии" path="vacancies">
      <UserVacancyList />
    </Tab>}
    {record?.profileType === ProfileType.Employee && <Tab label="Резюме" path="cv">
      <UserCvList />
    </Tab>}
    {record?.profileType === ProfileType.Hirer && <Tab label="Менеджеры" path="managers">
      <ProfileManagerList />
    </Tab>}

    <Tab label="Сообщества" path="community">
      <ProfileCommunityList />
    </Tab>
  </TabbedShowLayout>)
}
const ProfileShow: FC<EditProps> = props => {
  return (<Show {...props}  title={<Title/>}>
   <Layout/>
  </Show>)
}

export default ProfileShow
