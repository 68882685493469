import * as React from 'react'
import {FC} from 'react'
import {
    Filter,
    List,
    ListProps,
    TextField,
    DateField,
    FunctionField,
    Datagrid,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import ProfileLinkField from 'src/components/fields/ProfileLinkField'
import ProfileInput from 'src/components/inputs/ProfileInput'

const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'Chat'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>
            <ProfileInput source={'profileId'} label={'Профиль 1'} alwaysOn resettable/>
            <ProfileInput source={'participantId'} label={'Профиль 2'} alwaysOn resettable/>
        </Filter>
    )
}

const ChatList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title="Чаты"
            sort={{field: 'id', order: 'DESC'}}
            empty={<EmptyList title={'Нет'} description={'Вы можете добавить'}
                              buttonText={'Добавить'}/>}>
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField type={'show'} source={'id'} label={'ID'}/>
                <TextField source={'name'} label={'Название'} />
                <FunctionField source={'IsGroup'} label={'Группа'} render={(i: any) => i.isGroup ? 'Да' : ''} />
                <ProfileLinkField label={'Профиль 1'} source={'profile'}/>
                <ProfileLinkField label={'Профиль 2'} source={'participant'}/>
                <DateField source={'lastMessageAt'} label={'Последнее сообщение'} showTime/>
                <DateField source={'createdAt'} label={'Создан'} showTime/>
            </Datagrid>
        </List>
    )
}

export default ChatList
