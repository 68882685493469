
export enum ArticleType {
    News = 'news',
    Knowledge = 'knowledge',
    Course = 'course',
    CourseNews = 'courseNews',
}
export enum ArticleCategoryType {
    News = 'news',
    Knowledge = 'knowledge',
    Course = 'course',
    CourseCompilation = 'courseCompilation',
}

export const ArticleTypeList = [
    { id: ArticleType.News, name: 'Новость' },
    { id: ArticleType.Knowledge, name: 'База знаний' },
    { id: ArticleType.Course, name: 'Курс' },
    { id: ArticleType.CourseNews, name: 'Статья курса' },
]


export const ArticleCategoryTypeList = [
    { id: ArticleCategoryType.News, name: 'Статьи' },
    { id: ArticleCategoryType.Knowledge, name: 'База знаний' },
    { id: ArticleCategoryType.Course, name: 'Курсы' },
    { id: ArticleCategoryType.CourseCompilation, name: 'Подборкаи курсов' },
]
