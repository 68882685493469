import * as React from 'react'
import {FC, useMemo} from 'react'
import {
    Filter,
    List,
    ListProps,useResourceContext,
    Datagrid, SearchInput, TextField,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import {csvExporter, formatCsvDate} from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import TransTextField from 'src/components/fields/TransTextField'
import LinkField from 'src/components/fields/LinkField'

const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'ArticleCategory'
    })
}

const _Filter = (props: any) => {
    return (
        <Filter {...props}>
            <SearchInput source="translations.title:$contL"  variant={'outlined'} alwaysOn/>

        </Filter>
    )
}

const ArticleCategoryList: FC<ListProps> = props => {
    const res = useResourceContext()
    console.log('resource11', res)
    const title = useMemo(() => {
        switch(res){
            case 'article-category-knowledge':
                return 'Категории база знаний'
            case 'article-category-news':
                return 'Категории статей'
            case 'course-category':
                return 'Категории курсов'
            case 'course-compilation-category':
                return 'Категории подборок курсов'
        }
    }, [res])
    const articlesLinkResource = useMemo(() => {
        switch(res){
            case 'article-category-knowledge':
                return 'article-knowledge'
            case 'article-category-news':
                return 'article-news'
            case 'course-compilation-category':
                return ''
            case 'course-category':
                return null
        }
    }, [res])
    return (
        <List
            {...props}
            exporter={exporter}
            filters={<_Filter/>}
            title={title}
            sort={{field: 'sort', order: 'ASC'}}
            filter={{'translations.languageCode': 'ru'}}
            empty={<EmptyList title={'Нет Категорий'} description={'Вы можете добавить категорию'}
                              buttonText={'Добавить категории'}/>}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField source={'id'} label={'ID'}/>
                <TransTextField source={'name'} label={'Название'}></TransTextField>
                <TextField source={'sort'} label={'Сортировка'}/>
                {articlesLinkResource ?   <LinkField source="articlesCount" value={'Статьи'}  label={'Статьи'} link={`/${articlesLinkResource}?displayedFilters={"articleCategoryId":true}&filter={"articleCategoryId":$id}`}/>
                    :   <TextField source="articlesCount" value={'Статьи'}  label={'Статьи'} />}

            </Datagrid>
        </List>
    )
}

export default ArticleCategoryList
