import ListIcon from '@mui/icons-material/List'
import PersonalPageList from 'src/resources/personal-page/PersonalPageList'
import PersonalPageEdit from 'src/resources/personal-page/PersonalPageEdit'
import PersonalPageShow from 'src/resources/personal-page/PersonalPageShow'

export default {
  show: PersonalPageShow,
  edit: PersonalPageEdit,
  list: PersonalPageList,
  icon: ListIcon,
}