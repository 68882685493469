
export enum Compensation {
    Salary = 'salary',
    Commission = 'commission',
    Gybrid = 'gybrid',
    Piecework = 'piecework',
    Contract = 'contract',
}

export const CompensationList = [
    {id: Compensation.Salary, name: 'Оклад'},
    {id: Compensation.Commission, name: 'Процент'},
    {id: Compensation.Gybrid, name: 'Гибрид'},
    {id: Compensation.Piecework, name: 'Сдельная'},
    {id: Compensation.Contract, name: 'Договорная'},
]