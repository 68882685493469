import ListIcon from '@mui/icons-material/List'
import StopwordList from 'src/resources/stopword/StopwordList'
import StopwordEdit from 'src/resources/stopword/StopwordEdit'
import StopwordCreate from 'src/resources/stopword/StopwordCreate'

export default {
  create: StopwordCreate,
  edit: StopwordEdit,
  list: StopwordList,
  icon: ListIcon,
}