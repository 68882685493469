import * as React from 'react'
import {
    SimpleForm, SelectInput, TextInput, ReferenceInput, required, ArrayInput, SimpleFormIterator
} from 'react-admin'
import {BackgroundTypeList} from 'src/types/enum/BackgroundType'
import {ProfileTypeList} from 'src/types/interfaces/IProfile'
import {MediaInput} from 'src/components/inputs/MediaInput'
import {getTranslation} from 'src/utils/translation'

const BackgroundForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <ReferenceInput  source="categoryId" reference="background-category"  variant={'outlined'}
                       filter={{'translations.languageCode': 'ru'}}
                       sort={{field: 'translations.name', order: 'ASC'}} perPage={100}  validate={[required()]}>
        <SelectInput  fullWidth={true} validate={required()}  label="Категория"
                      optionText={(record) => `${getTranslation(record)?.name}`} />
      </ReferenceInput>
      <SelectInput source={'backgroundType'} label={'Тип фона'} choices={BackgroundTypeList} fullWidth variant={'outlined'}/>
      <SelectInput source={'profileType'} label={'Тип профиля'} choices={ProfileTypeList} fullWidth variant={'outlined'}/>
      <TextInput source={'sort'} label={'Сортировка'} />
      <MediaInput source={'image'} label={'Rive или картинка'}   accept={['*/*']}/>
        <ArrayInput source="riveKeys" label={'Rive keys'}>
            <SimpleFormIterator fullWidth>
                <TextInput source="key" label={'Key'} variant={'outlined'} fullWidth validate={required()} />
            </SimpleFormIterator>
        </ArrayInput>
    </SimpleForm>
  )
}
export default BackgroundForm
