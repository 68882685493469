import * as React from 'react'
import { SimpleForm, TextInput,
} from 'react-admin'

const SourceForm = (props: any) => {
  return (
    <SimpleForm {...props} redirect={'list'}>
      <TextInput source="title"  label={'Название'} fullWidth variant={'outlined'}/>

    </SimpleForm>
  )
}
export default SourceForm
