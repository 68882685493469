import * as React from 'react'
import { FC } from 'react'
import {
    List,
    ListProps,
    FunctionField,
    TextField,
    SearchInput,
    Datagrid, TextInput, ReferenceInput, SelectInput, SelectField,
} from 'react-admin'
import IdShowButtonField from 'src/components/fields/IdShowButtonField'
import { csvExporter, formatCsvDate } from 'src/utils/exporter'
import EmptyList from 'src/components/list/EmptyList'
import TransTextField from 'src/components/fields/TransTextField'
import AssetField from 'src/components/fields/AssetField'
import { getTranslation } from 'src/utils/translation'
import {GenderList} from 'src/types/interfaces/IProfile'

const exporter = (posts: any[]) => {
    return csvExporter({
        columns: {
            'createdAt': 'Создано',
        },
        data: posts.map((item: any) => ({
            ...item,

            createdAt: formatCsvDate(item.createdAt),
            updatedAt: formatCsvDate(item.updatedAt)
        })),
        fileName: 'CharacterItem'
    })
}

const filters = [
    <SearchInput key={1} source="translations.name:$contL"  variant={'outlined'} fullWidth alwaysOn />,
    <ReferenceInput key={2} source="categoryId" reference="character-item-category" alwaysOn resettable
        sort={{ field: 'translations.name', order: 'ASC' }} perPage={100} >
        <SelectInput variant={'outlined'} fullWidth helperText={false} label="Категория"
            optionText={(record) => `${getTranslation(record)?.name}`} />
    </ReferenceInput>,
    <TextInput key={3} source="animationKey:$contL" label={'Ключ'} variant={'outlined'} fullWidth />

]
const CharacterItemList: FC<ListProps> = props => {
    return (
        <List
            {...props}
            exporter={exporter}
            filters={filters}
            title="Вещи"
            sort={{ field: 'id', order: 'DESC' }}
            empty={<EmptyList title={'Нет'} description={'Вы можете добавить'}
                buttonText={'Добавить'} />}
        >
            <Datagrid bulkActionButtons={false}>
                <IdShowButtonField source={'id'} label={'ID'} />
                <AssetField source={'image'} label={'Картинка'} />
                <TransTextField source={'name'} label={'Название'} />
                <TextField source={'price'} label={'Цена'} />
                <TextField source={'animationKey'} label={'Ключ'} />
                <SelectField source={'gender'} label={'Пол'} choices={GenderList}/>
                <FunctionField source={'isNew'} label={''} render={(i: any) => i.isNew ? 'Новая' : ''} />
            </Datagrid>
        </List>
    )
}

export default CharacterItemList
